import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function Mainpage() {

  const [plan, setPlan] = useState([]);
  const [vlidity, setvlidity] = useState('');
  const [lead, setLead] = useState('');
  const [bonuslead, setBonuslead] = useState('');
  const [payment, setPayment] = useState('');
  const [walletid, setWalletId] = useState('10000000');
  const [amount, setAmount] = useState('');
  const [msg, setMsg] = useState('');

  const [usr, setUsr] = useState('');
  const [blance, setBalance] = useState('');

  const [alrt, setArt] = useState(false);
  

  const navigate = useNavigate();


  const getBlance = async id => {
    const data = new FormData();

    // localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/wallet_last.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        if (s.data[0].code === 'pass') {
          setBalance(s.data[0].blance);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    setUsr(v);
  
    if (v === null) {
      navigate("/login");
    } else {
      getBlance(v[0]?.id);
  
      const jj = JSON.parse(localStorage.getItem("package_plan"));
      const select = Number(localStorage.getItem("package_select"));
  
      if (Array.isArray(jj) && jj[select]) {
        const asa = jj[select]?.title || null;
        console.log(select);
        console.log("asa=>", asa);
        console.log("jj=>", jj);
  
        const result = jj.find(({ title }) => title === asa);
  
        if (result) {
          setvlidity(result.month || 0);
          setBonuslead((result.bonusleads || 0) * Number(result.month || 0));
          setLead(
            (Number(result.lead || 0) * Number(result.month || 0)) +
              (Number(result.bonusleads || 0) * Number(result.month || 0))
          );
          console.log(result);
  
          setPlan(result);
        } else {
          console.error("Result not found in package_plan");
        }
      } else {
        console.error("Invalid package_plan or package_select");
      }
    }
  }, []);  


  const sendPayment = async id => {

    const data = new FormData();

    data.append('uidd', walletid);
    data.append('uid', id);
    data.append('amount', plan.purchase_price);

    axios({
      method: 'post',
      url: '/pro/send_payment.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        

        if (s.data == '') {
          console.log('data. s is empty withdraw.js');
        } else {
          if (s.data[0].code == 'pass') {
            setMsg('you are successfully purchase Subscription Package.');
            setArt(true);
            getBlance(id)
            
            donePackage(id)
          } else {
            setMsg('Balance Low');
            setArt(true);
          }
        }
      })
      .catch(s => {
        console.log('catch issue  withdraw.js');
      });
  };

  const donePackage = async (id) => {

    const data = new FormData();

    data.append('array', JSON.stringify(plan));
    data.append('uid', id);
    data.append('v',vlidity);
    data.append('lead',plan.lead*plan.month );
    data.append('bonuslead',bonuslead );

    axios({
      method: 'post',
      url: '/pro/purchase_plan.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);

        if (s.data == '') {
          console.log('data. s is empty withdraw.js');
        } else {
          if (s.data[0].code == 'pass') {
            setMsg('Payment Send');
            setArt(true);

          } 
        }
      })
      .catch(s => {
        console.log('catch issue  withdraw.js');
      });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}

            {/* 2nd Table  */}

            <div className="content-wrap">
              <div className="main">
                <div className="container-fluid">
                  {/* <!-- /# column --> */}

                  {/* <!-- /# row --> */}
                  <section id="main-content">
                    {/* <!-- /# column --> */}
{
  alrt?<>
 
                        
 <SweetAlert
                            show={alrt}
                            title={msg}
                            
                            onConfirm={() => {
                              setArt(false);
                              
                              navigate('/packages');
                            }}
                          />

  </>:
  <>
  </>
}

                    {/* <!-- /# column --> */}
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h4> Subscription / Package   </h4>
                        </div>

                        <section id="main-content">
                          {/* <!-- /# column --> */}

                          {/* <!-- /# column --> */}
                          <div className="col-lg-12">
                            <div className="card">
                              <div className="card-title">
                              <center>
                              <h2 ><b>Subscription Invoice </b> </h2>
                              </center>
                              </div>
                              <div style={{marginTop:"25px"}} className='row'>
                               <div className='col-6' >
                                <h2>Order Subscription </h2>
                               </div>
                               <div className='col-3' >
                                <h4>Email :  {usr.length>0?usr[0].email:""}  </h4>
                               </div>
                               <div className='col-3' >
                                <h4>E-Wallet ID:  {usr.length>0?usr[0].id:""}</h4>
                               </div>
                              </div>

                              <div className="card-body"></div>
                              <div className="table-responsive">
                                <table className="table  ">
                                  <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Quantity</th>
                                      <th>Name</th>
                                      <th>Price</th>
                                   
                                      <th>Month</th>
                                      <th>Total Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>1</td>
                                      <td>{plan.length===0?<></>:<>{plan.title}</>}</td>
                                      <td>{plan.length===0?<></>:<>{plan.price}</>}</td>
                                      <td>{plan.length===0?<></>:<>{plan.month}</>}</td>
                                     
                                      
                                      <td>{plan.length===0?<></>:<>{plan.purchase_price}</>}</td>
                                   
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              {/* Form Start */}
                              <div
                                style={{marginTop: '20px'}}
                                className="card-title">
                                <h3> Payment Details</h3>
                              </div>
                              <div style={{marginTop:"25px"}} className="row">
                              
                                <div className="col-4 form-group">
                                  <label> <h6>Payment Method  </h6> </label>
                                 <br/>
                                  <input type={"text"} className="form-control" disabled value={"E-Wallet"} />
                                  
                                </div>
                                <div className="col-4 form-group">
                                  <label>Available Balance 

                                    <span style={{marginLeft:"30px"}}> 
                                    <button  onClick={()=>{
                                       navigate('/topup');
                                    }}   className="btn-primary " ><i className="ti ti-plus mx-2 "></i>Add Payment</button>
                                    
                                     </span>

                                      </label>
                                 <br/>
                                  <input type={"text"} className="form-control" disabled value={new Intl.NumberFormat('en-pk').format( blance)+" PKRs"} />
                                  
                                </div>
                              </div>
                    
                              <div className="row">
                      
                                  <button
                                  onClick={()=>{
           
              sendPayment(usr[0].id);
           

              

            }
                                  }
                                    style={{
                                      textDecoration: 'none',
                                    }}

                                    className="col-2 py-2 my-3 mx-2 btn-primary">
                                   Pay Invoice
                                  </button>
                            
                              </div>
                              {/* form End */}
                            </div>
                          </div>
                        </section>
                        {/* form End */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            {/* Extra end */}
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
