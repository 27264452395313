import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../../../navbar";
import DataTable from "react-data-table-component";

function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [lead, setLead] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [darw, setDarw] = useState(true);

  // Set the default base URL
  axios.defaults.baseURL =
    window.location.hostname === "localhost"
      ? "http://localhost"
      : "https://" + window.location.hostname;

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      lead_view_all(v[0].id);
    }
  }, []);

  const checkvalidity = (lead_id) => {
    localStorage.setItem("leadid", lead_id);
    navigate("/sendquote");
  };

  const lead_view_all = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/lead_view_all.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setLead(s.data);
      })
      .catch((s) => {
        console.error("Error fetching leads:", s);
      });
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  // Define columns with responsive priorities
  const columns = [
    {
      name: "#",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
    },
    {
      name: "User",
      selector: (row) => row?.username || "N/A",
      sortable: true,
      wrap: true,
      hide: "sm", // Hide on small screens
    },
    {
      name: "Product Name",
      selector: (row) => JSON.parse(row.sheet)[0]?.name || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => JSON.parse(row.sheet)[0]?.qty || "N/A",
      sortable: true,
      hide: "md", // Hide on medium screens
    },
    {
      name: "Price",
      selector: (row) => JSON.parse(row.sheet)[0]?.price || "N/A",
      sortable: true,
      hide: "md", // Hide on medium screens
    },
    {
      name: "Unit",
      selector: (row) => JSON.parse(row.sheet)[0]?.piece || "N/A",
      sortable: true,
      hide: "lg", // Hide on large screens
    },
    {
      name: "Description",
      selector: (row) => JSON.parse(row.sheet)[0]?.des || "N/A",
      sortable: true,
      hide: "lg", // Hide on large screens
    },
    {
      name: "Time",
      selector: (row) => row.time || "N/A",
      sortable: true,
      hide: "lg", // Hide on large screens
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => checkvalidity(row.lead_id)}
        >
          Send Quote
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // Define the expandable row content
  const ExpandableRow = ({ data }) => {
    const sheetData = JSON.parse(data.sheet)[0] || {};
    return (
      <div style={{ padding: "10px" }}>
        <p>
          <strong>User Name:</strong> {data.username || "N/A"}
        </p>
        <p>
          <strong>Product Name:</strong> {sheetData.name || "N/A"}
        </p>
        <p>
          <strong>Quantity:</strong> {sheetData.qty || "N/A"}
        </p>
        <p>
          <strong>Price:</strong> {sheetData.price || "N/A"}
        </p>
        <p>
          <strong>Unit:</strong> {sheetData.piece || "N/A"}
        </p>
        <p>
          <strong>Description:</strong> {sheetData.des || "N/A"}
        </p>
        <p>
          <strong>Time:</strong> {data.time || "N/A"}
        </p>
      </div>
    );
  };

  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{ overflow: "auto", display: "flex", flexDirection: "column", padding: "0px" }}
        className={
          darw
            ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
            : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
        }
      >
        <div className="container-fluid">
          <div className="row mb-3">
          <h4 className="mt-3"> Product Buy Requests</h4>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <DataTable
            columns={columns}
            data={handleSearch(lead, searchText)}
            pagination
            highlightOnHover
            responsive
            expandableRows
            expandableRowsComponent={ExpandableRow}
          />
        </div>
      </div>
    </div>
  );
}

export default Buyleads;
