import Nav from "./navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import '../styles/sellerdashboard.css';
import Img1 from '../images/img1.png';
import Img2 from '../images/img2.png';
import Img3 from '../images/img3.png';
import Watsapp from '../images/watsapp.png';
import DataTable from "react-data-table-component";

const Dashboard = () => {
    const [darw, setDarw] = useState(true);
  const [userName, setUserName] = useState("");
  const [chartData, setChartData] = useState({ categories: [], debit: [], credit: [] });
  const [bestClients, setBestClients] = useState([]);
  const [totals, setTotals] = useState({ due_invoice: 0, invoiced_amount: 0, total_invoices: 0 });
  const [expectedAmount, setExpectedAmount] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("USER"));

  const fetchDashboardData = async () => {
    setLoadingState(true);
    const data = new FormData();
  
    try {
      // Ensure user data exists and is parsed correctly
      if (!user || !user[0]?.id) {
        setError("User information is missing.");
        return;
      }
  
      data.append("uid", user[0].id);
      data.append("api_type", "all");
  
      const response = await axios.post("/pro/khata/dashboard.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      if (response.data.code === "success") {
        const result = response.data;
  
        // Safely process bar chart data
        const categories = (result.debits_credits || [])
          .map((item) => item.client_name || "Unknown");
        const debit = (result.debits_credits || [])
          .map((item) => parseFloat(item.total_debit) || 0);
        const credit = (result.debits_credits || [])
          .map((item) => parseFloat(item.total_credit) || 0);
  
        setChartData({ categories, debit, credit });
  
        // Safely process best clients
        setBestClients(result.best_clients || []);
  
        // Safely process totals
        setTotals(result.totals || { due_invoice: 0, invoiced_amount: 0, total_invoices: 0 });
  
        // Safely process expected amounts
        setExpectedAmount(result.expected_amount || []);
  
        // Set user name safely
        setUserName(user[0]?.name || "User");
      } else {
        setError(response.data.message || "An unexpected error occurred.");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoadingState(false);
    }
  };  

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const generateAvatar = (name, size = 50) => {
    // If name is null, undefined, or empty, set it to "?"
    if (!name || typeof name !== "string") {
      name = "?";
    }

    const words = name.trim().split(/\s+/);
    const initials = words.length > 1 ? words[0][0].toUpperCase() + words[1][0].toUpperCase() : words[0][0].toUpperCase();

    const generateColorFromName = (name) => {
      let hash = 2166136261;
      for (let i = 0; i < name.length; i++) {
        hash ^= name.charCodeAt(i);
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
      }
      const r = (hash & 0xff0000) >> 16;
      const g = (hash & 0x00ff00) >> 8;
      const b = hash & 0x0000ff;
      return `rgb(${r}, ${g}, ${b})`;
    };

    const bgColor = generateColorFromName(name);

    const canvas = document.createElement("canvas");
    canvas.width = canvas.height = size;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, size, size);

    ctx.fillStyle = "#ffffff";
    ctx.font = `${size / 2}px Arial`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(initials, size / 2, size / 2);

    return canvas.toDataURL("image/png");
  };

  const chartOptions = {
    chart: { type: "bar", toolbar: { show: false } },
    xaxis: { categories: chartData.categories },
    colors: ["#007bff", "#28a745"],
    dataLabels: { enabled: false },
  };

  const chartSeries = [
    { name: "Debit", data: chartData.debit },
    { name: "Credit", data: chartData.credit },
  ];

  return (
    <div>
            <Nav setDarw={setDarw} darw={darw} />

            <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', padding: '0px' }} className={darw ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ') : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ')}>
      <div className="container-fluid">
        <div className="row">
          <div className="banner-seller-dashboard pb-3">
                            <span className="ml-2 text-light">Hi, Welcome {userName} </span><br />
                            <p className="mt-2 ml-2 text-light">
                                Welcome  to Dashboard, Here You Can  also give, <br />
                                Feature info or message about the up page
                            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-xl-8 col-md-12">
            <div className="card">
              <h5 className="text-center">Total Debit vs Credit</h5>
              <Chart options={chartOptions} series={chartSeries} type="bar" height={300} />
            </div>

            <div className="row mt-4">
              <div className="col-md-4">
                <div className="card text-center shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">Due Invoice</h5>
                    <p className="card-text display-6">{totals.due_invoice}</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card text-center shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">Invoiced Amount</h5>
                    <p className="card-text display-6">{totals.invoiced_amount}</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card text-center shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title">Total Invoices</h5>
                    <p className="card-text display-6">{totals.total_invoices}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-12">
          <div className="flex-xl-column w-100">
            <div className="card card-khata-rightisdebar-rounded">
              <div className="card-header bg-white text-center">
                <h4 className="mb-0">Top Best Clients</h4>
              </div>
              <div className="card-body card-body-khata-top-clients">
                {bestClients.map((client, index) => (
                  <div key={index} className="d-flex align-items-center p-3 mb-2 border rounded" style={{ backgroundColor: "#E4F7FF" }}>
                    <img
                      src={generateAvatar(client.client_name)}
                      alt={`${client.client_name}'s profile`}
                      className="rounded-circle me-3"
                      style={{ width: "50px", height: "50px", objectFit: "cover" }}
                    />
                    <div className="flex-grow-1">
                      <h6 className="mb-1 text-truncate font-size-14">{client.client_name}</h6>
                      <p className="mb-0 text-muted font-size-12">Total Balance</p>
                    </div>
                    <strong className="text-dark font-size-14 font-weight-800">{client.total_balance}</strong>
                  </div>
                ))}
              </div>
            </div>

            <div className="card card-khata-rightisdebar-rounded">
              <div className="card-header bg-white text-center mb-2">
                <h4 className="mb-0">Expected Amount</h4>
              </div>
              {expectedAmount.map((item, index) => (
                <div key={index} className="border-1 shadow-sm mt-2">
                  <div className="card-body d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="mb-2">
                      <strong>Client Name:</strong> <span className="d-block text-muted">{item.client_name}</span>
                      </div>
                                                    <div className="mb-2">
                      <strong>Invoice Due Date:</strong> <span className="d-block text-muted">{item.invoice_due_date}</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                    <div className="mb-3">
                                                        <strong>Amount</strong><span className="d-block text-muted">{item.amount}</span>
                                                    </div>
                   
                                                    <button
                                                        className="btn d-flex align-items-center border rounded-pill"
                                                        style={{ width: "fit-content", backgroundColor: '#42A253', color: '#fff' }}
                                                    >Send Reminder</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
