import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

function Mainpage() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [name, setName] = useState("");
  const [allChallan, setAllChallan] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user) {
      setUid(user[0].id);
      setName(user[0].name);
      getChallan(user[0].id);
    }
  }, []);

  const getChallan = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "view_challan");

    axios({
      method: "post",
      url: "/pro/khata/manage_challan.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setAllChallan(response.data);
      })
      .catch((error) => {
        console.error("Error fetching challans:", error);
      });
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        // Make sure to handle `name` properly if it's used directly
        String(value).toLowerCase().includes(searchText.toLowerCase())
      ) || (name && String(name).toLowerCase().includes(searchText.toLowerCase())) // Include search for "From"
    );
  };

  const columns = [
    {
      name: "#",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
    },
    {
      name: "From",
      selector: () => name || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "To",
      selector: (row) => row.username || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Challan Date",
      selector: (row) => JSON.parse(row.challanarray)[0]?.challandate || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Items Included",
      selector: (row) => JSON.parse(row.challanarray)[0]?.item || "N/A",
      sortable: true,
      hide: "md",
    },
    {
      name: "No of Pieces",
      selector: (row) => JSON.parse(row.challanarray)[0]?.piece || "N/A",
      sortable: true,
      hide: "md",
    },
    {
      name: "Approx Weight",
      selector: (row) => JSON.parse(row.challanarray)[0]?.weight || "N/A",
      sortable: true,
      hide: "lg",
    },
    {
      name: "Bilty No",
      selector: (row) => JSON.parse(row.challanarray)[0]?.builtyno || "N/A",
      sortable: true,
      hide: "lg",
    },
    {
      name: "Courier Name",
      selector: (row) => JSON.parse(row.challanarray)[0]?.courier || "N/A",
      sortable: true,
      hide: "lg",
    },
    {
      name: "Status",
      cell: () => (
        <span style={{ color: "red", fontWeight: "bolder" }}>Sent</span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const ExpandableRow = ({ data }) => {
    const challanData = JSON.parse(data.challanarray)[0] || {};
    return (
      <div style={{ padding: "10px" }}>
        <p>
          <strong>Items Included:</strong> {challanData.item || "N/A"}
        </p>
        <p>
          <strong>No of Pieces:</strong> {challanData.piece || "N/A"}
        </p>
        <p>
          <strong>Approx Weight:</strong> {challanData.weight || "N/A"}
        </p>
        <p>
          <strong>Bilty No:</strong> {challanData.builtyno || "N/A"}
        </p>
        <p>
          <strong>Courier Name:</strong> {challanData.courier || "N/A"}
        </p>
      </div>
    );
  };

  return (
    <div>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Manage Delivery Challans</h4>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate("/adddeliverychallan")}
                      >
                        Add Challan
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <DataTable
                      columns={columns}
                      data={handleSearch(allChallan, searchText)}
                      pagination
                      highlightOnHover
                      responsive
                      expandableRows
                      expandableRowsComponent={ExpandableRow}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mainpage;
