import React, { useState, useEffect } from "react";
import '../styles/footer.css';
import axios from "axios";

const SearchInput = ({ placeholder, value, setValue, itemValue }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [lastQuery, setLastQuery] = useState("");

  useEffect(() => {
    if ((allSuggestions.length == 0 && value.length >= 3) || (value.length === 3 && value !== lastQuery)) {
      fetchSuggestions(value.slice(0, 3));
    } else if (value.length >= 3) {
      const filteredSuggestions = allSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else if (value.length < 3) {
      setSuggestions([]);
      setAllSuggestions([]);
      setLastQuery("");
    }
  }, [value]);

  const fetchSuggestions = async (query) => {
    try {
      const response = await axios.get(
        `/mainsite/getProducts.php?suggestion=${query}&itemValue=${itemValue}`
      );

      if (response.data.suggestions) {
        setSuggestions(response.data.suggestions);

        const filteredSuggestions = allSuggestions.filter((suggestion) =>
          suggestion.toLowerCase().startsWith(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);

        setAllSuggestions(response.data.suggestions);
      } else {
        setSuggestions([]);
        setAllSuggestions([]);
      }

      setLastQuery(query);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      window.location.href = `/search?product=${value}`;
      if (value.length < 4) {
        alert("Search requires a minimum of 4 characters!");
      } else {
        localStorage.setItem("title", value);
        if (itemValue === "product") {
          window.location.href = `/search?product=${value}`;
        } else if (itemValue === "company") {
          window.location.href = `/search?supplier=${value}`;
        } else if (itemValue === "supp") {
          window.location.href = `/supplier?name=${value}`;
        } else if (itemValue === "cat") {
          window.location.href = `/sub/${value}`;
        }
      }
    }
  };

  const handleSuggestionClick = (suggestion, event) => {
    setValue(suggestion);
    setSuggestions([suggestion]);

    const rowDiv = event.currentTarget.closest(".row");
    if (rowDiv) {
      const button = rowDiv.querySelector(".btn-search");
      if (button) {
        setTimeout(() => {
          button.click();
        }, 100);
      }
    }
  };

  return (
    <div style={{
      position: "relative", width: '100%'
    }}>
      {/* <a class="dropdown"> */}
      <input
        type="text"
        placeholder={placeholder}
        class="main-item-search"
        // id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"
        aria-label="Search"
        style={{
          border: 'none',
          width: '100%',
          height: '100%'
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      {/* </a> */}
      {value.length >= 3 && suggestions.length > 0 && (
        <ul
          className="list-group mt-1 list-group-ul-navbar"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            zIndex: 1000,
            borderRadius: "0 0 0.5rem 0.5rem",
            overflow: "hidden",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: '25px !important',
            background: 'transparent'
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              class="list-group-item list-group-item-action dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              onClick={(e) => {
                handleSuggestionClick(suggestion, e);
                localStorage.setItem("title", suggestion);
                window.location.href = `/search?product=${suggestion}`;
              }}
              style={{
                cursor: "pointer",
                padding: "0.75rem 1.25rem",

              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = "white";
                e.currentTarget.style.backgroundColor = "#4f97a1";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = "";
                e.currentTarget.style.backgroundColor = "";
              }}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
