import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

import Whatsapp from '../components/landingpage/whatsapp'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, onValueChange } from "firebase/database";
import Header from "./header";
import Footer from "./footer";
import { redirectToLoginWithBackto } from "../pages/utils";
import Breadcrumbs from "./breadcrumbs";

function Product_Detail() {

  const navigate = useNavigate();
  const [sheet, setSheet] = useState([
    {
      id: 1,
      name: "",
      quantity: "",
      piece: "",
      price: "",
      des: "",
      status: "normal",
    },
  ]);

  const [mesg, setMesg] = useState("");
  const [pid, setpid] = useState("");
  const [uid, setUid] = useState("");
  const [piduser, setpidUser] = useState("");
  const [catid, setcatid] = useState("");
  const [chatid, setChatid] = useState("");
  const [chatUser, setChatUser] = useState("");

  const [user, setUser] = useState([]);
  const [allcats, setAllcats] = useState([]);
  const [alert2, setAlert2] = useState(false);
  const [allUID, setAllUID] = useState([]);
  const [allSimilar, setAllSimilar] = useState([]);



  useEffect(() => {
    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);

      // console.log("sss", as);
      setUser(as);

      setUid(as[0].id);
    } else {
      // console.log("Login Please");
    }

    getServercats();
  }, []);

  const getServercats = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    setpid(id);

    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/mainsite/productbyid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        getServerUID(s.data[0].uid);
        setcatid(s.data[0].cat);
        changeArayquote(0, { name: s.data[0].name });
        getServerSimilar(s.data[0].cat);
        setpidUser(s.data[0].uid);
        // console.log("product", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const getServerUID = async (uid) => {
    const data = new FormData();

    data.append("uid", uid);

    axios({
      method: "post",
      url: "/mainsite/productbyuid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllUID(s.data);
        setChatUser(s.data[0].uid);
        // console.log("Product By Uid", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const getServerSimilar = async (cat) => {
    const data = new FormData();
    data.append("cat", cat);

    axios({
      method: "post",
      url: "/mainsite/productbycat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllSimilar(s.data);
        // console.log(" set All Similar ", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const SameUser = () => {
    if (uid === piduser) {
      console.log("Please Select Other Product");
      alert("Please Select Other Product because This is your product");
    } else {
      viewchat(uid, piduser);
      //console.log(uid)
      //console.log(piduser)
      //add_lead();
    }
  };

  const generateMsg = (v, sheet) => {
    // Generate the HTML structure
    const messageHTML = `
      <div style="border: 1px solid #ccc; border-radius: 10px; padding: 10px; max-width: 300px; font-family: Arial, sans-serif;">
        <div style="text-align: center; margin-bottom: 10px;">
          <img src="${v.img}" alt="Product Image" style="height: auto; width: 100%; max-width: 200px; border-radius: 8px;" />
        </div>
        <h3 style="margin: 0; font-size: 16px; color: #333;">${sheet[0].name}</h3>
        <p style="margin: 5px 0; font-size: 14px; color: #666;">
          <strong>Quantity:</strong> ${sheet[0].qty} ${sheet[0].piece}<br />
          <strong>Price:</strong> Rs ${sheet[0].price}<br />
          <strong>Details:</strong> ${sheet[0].des}
        </p>
      </div>
    `;

    return messageHTML;
  };

  const add_lead = async (cid) => {
    if (user.length > 0) {
      const data = new FormData();
      data.append("uid", user[0].id);
      data.append("sheet", JSON.stringify(sheet));
      data.append("cat", catid);
      data.append("pid", pid);
      //data.append("two", piduser);
      //data.append("msg", mesg);

      axios({
        method: "post",
        url: "/mainsite/get_quote.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((s) => {
          //viewchat();
          // console.log(s.data);
          chatinbox(cid);
        })
        .catch((s) => {
          // console.log("here catch =>>", s);
        });
    } else {
      alert("Please Login Now");
    }
  };

  const viewchat = async (u, p) => {
    const data = new FormData();
    data.append("one", u);
    data.append("two", p);

    axios({
      method: "post",
      url: "/mainsite/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "empty") {
          redirectToLoginWithBackto();
        }
        if (a[0].code == "pass") {
          // setProductList(a);
          setChatid(s.data[0].chatid);
          // console.log(chatid);
          //chatinbox(s.data[0].chatid);
          add_lead(s.data[0].chatid);
          // localStorage.setItem("ProductListInventory", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const chatinbox = async (chatid) => {
    //setMsg("");
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", mesg);
    data.append("type","imageText");
    if (mesg === "") {
      alert(mesg);
    } else {
      // alert(mesg);

    }

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox Message ", s.data);

        if (chatid !== "") {
          const a = s.data;
          saveToFirebaseDatabse(a);
          localStorage.setItem("piduser", chatid);
          navigate("/chat?cid=" + chatUser);
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const firebaseConfig = {
    apiKey: "AIzaSyCNThPGDzsQTuxrGZYBuG-H8yCK4CTg1Jo",
    authDomain: "alahdeen-24173.firebaseapp.com",
    databaseURL: "https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "alahdeen-24173",
    storageBucket: "alahdeen-24173.appspot.com",
    messagingSenderId: "298890559548",
    appId: "1:298890559548:web:6fda9c54ae5e295d868339",
    measurementId: "G-6Q2NLSNF9T"
  };
  const app = initializeApp(firebaseConfig);
  const saveToFirebaseDatabse = async (msg) => {
    const chatid = msg[0].cid;
    const id = msg[0].id;
    const send = msg[0].sendby;
    const mess = msg[0].mesg;
    const date = msg[0].date;
    const status = msg[0].status;
    const time = msg[0].time;
    const type = msg[0].type;
    const user_name = msg[0].user_name ?? '';


    const db = getDatabase(app);
    set((ref(db, '/chat/' + chatid + '/' + id)), {
      id: id,
      chat_id: chatid,
      sent_by: send,
      message: mess,
      date: date,
      status: status,
      time: time,
      type: type,
      user_name: user_name,
    });

  }

  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };


  const [selectedImage, setSelectedImage] = useState(null);




  const openImage = (imageUrl) => {
    setSelectedImage(imageUrl); // Open the clicked image in full-screen
  };

  const closeImage = () => {
    setSelectedImage(null); // Close the full-screen view
  };

  return (
    <>
      <Header />
      <Whatsapp />
      <main className="main">

        {/* <div className="container">
          <div className="product-images">
            <img src="https://alahdeen.com/assets/images/banner/1.png" alt="Main Product" className="main-image" />
            Additional thumbnails can be placed here
          </div>
          
        </div> */}





        {allcats.map((v, i) => (
          <>
            <Breadcrumbs categoryId={v.cat} />


            <div className="container-fluid">
              <div className="row mx-2 mx-lg-3 ">
                <div className="col-12 col-lg-6">
                  {/* <div className="product-images">
                <img src="https://alahdeen.com/assets/images/banner/1.png" alt="Main Product" className="main-image border rounded w-100" />
              </div> */}
                  <div className="product-image-slider">
                    <Carousel variant="dark" style={{ borderRadius: '25px' }}>
                      <Carousel.Item style={{ borderRadius: '25px' }}>
                        <div>
                          <img
                            height="350px"

                            src={v.img}
                            alt="First slide"
                            className="d-block w-100"
                            style={{ cursor: 'pointer', borderRadius: '25px' }}
                            onClick={() => openImage(v.img)}
                          />
                        </div>

                      </Carousel.Item>
                      <Carousel.Item style={{ borderRadius: '25px' }}>
                        <img
                          height="350px"
                          className="d-block w-100"
                          src={v.img_a}
                          alt="First slide"
                          style={{ cursor: 'pointer', borderRadius: '25px' }}
                          onClick={() => openImage(v.img_a)}
                        />

                      </Carousel.Item>

                      <Carousel.Item style={{ borderRadius: '25px' }}>
                        <img
                          height="350px"
                          className="d-block w-100"
                          src={v.img_b}
                          alt="First slide"
                          style={{ cursor: 'pointer', borderRadius: '25px' }}
                          onClick={() => openImage(v.img_b)}

                        />

                      </Carousel.Item>


                    </Carousel>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                  <div className="product-details"
                    style={{
                      // border: '1px solid black',
                      padding: '44px 33px 32px 33px',
                      boxShadow: '0 3.2rem 6.4rem rgba(0, 0, 0, 0.06)',
                      borderRadius: '25px',
                    }}
                  >
                    <h1 className="product-title fs-2" style={{
                      color: '#425A8B',
                      fontSize: '28px',
                      fontWeight: 700
                    }}>
                      {v.name}
                      {/* Samsung Galaxy S22 Ultra, 8K Camera & Video, Brightest Display Screen, S Pen Pro */}
                    </h1>
                    <div className="d-flex justify-content-between align-items-end my-3">
                      <div>
                        <p className="price fs-6" style={{ color: '#8C9EC5' }}>by <span></span></p>
                        <p className="price fs-6" style={{ fontWeight: 400, }}>Min Order Qty: <span>{v.minimum_quantity}</span></p>
                      </div>
                      <p className="price fs-3 font-bold" style={{
                        fontSize: '32px', color: '#010101', lineHeight:
                          '41px'
                      }}>
                        Price: {v.price}<br />
                        {v.pro_price != "" ? <>Discount Price :{v.pro_price} </> : <></>}
                      </p>
                    </div>
                    <div className="py-4" style={{
                      color: 'lightgray',
                      borderTop: '2px solid #DDE4F0',
                      borderBottom: '2px solid #DDE4F0'
                    }}>
                      <p className="description" style={{ color: '#425A8B', fontSize: '14px', lineHeight: '18px' }}>
                        <ul className="list-dot">
                          <li>SKU Code: {v.barcode} </li>
                          {/* <li>Reliable Product</li> */}
                          {/* <li>Minimum Order Quantity: {v.minimum_quantity}</li> */}
                          <li>Delivery Time {v.delivery}</li>

                        </ul>

                        {v.des}
                        {/* 8k super steady video. Nightography plus portrait mode. 50mp photo resolution plus bright display. Adaptive color contrast, premium design & craftsmanship. Long lasting battery plus fast charging. */}
                      </p>
                      <div className="actions mt-4">
                        <a href="#get-quote-form">
                        <button className="btn call-now border rounded-pill"
                          style={{
                            display: 'inline-block',
                            padding: '.5rem 3rem',
                            color: 'whitesmoke',
                            backgroundColor: '#1A78B4',
                            important: true
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 23" fill="none">
                            <path d="M12.8334 2.33331H5.50008C5.01385 2.33331 4.54754 2.52647 4.20372 2.87028C3.8599 3.2141 3.66675 3.68042 3.66675 4.16665V18.8333C3.66675 19.3195 3.8599 19.7859 4.20372 20.1297C4.54754 20.4735 5.01385 20.6666 5.50008 20.6666H16.5001C16.9863 20.6666 17.4526 20.4735 17.7964 20.1297C18.1403 19.7859 18.3334 19.3195 18.3334 18.8333V7.83331L12.8334 2.33331Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.8333 2.33331V7.83331H18.3333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.6666 12.4167H7.33325" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.6666 16.0833H7.33325" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.16659 8.75H8.24992H7.33325" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span className="ml-2">Get Quote</span>
                        </button>

                        </a>
                        {/* <button className="btn call-now border rounded-pill ml-sm-2 " style={{
                      display: 'inline-block',
                      padding: '.5rem 3rem',
                      color: 'whitesmoke',
                      backgroundColor: '#2C99A1',
                      important: true
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 23" fill="none">
                        <path d="M20.1666 16.01V18.76C20.1676 19.0153 20.1153 19.268 20.013 19.5019C19.9108 19.7358 19.7608 19.9458 19.5726 20.1184C19.3845 20.291 19.1624 20.4224 18.9206 20.5042C18.6787 20.5859 18.4225 20.6163 18.1682 20.5933C15.3475 20.2868 12.638 19.323 10.2574 17.7792C8.04257 16.3718 6.16479 14.494 4.7574 12.2792C3.20821 9.88778 2.24412 7.16509 1.94323 4.33167C1.92032 4.07819 1.95045 3.8227 2.03169 3.5815C2.11293 3.34029 2.2435 3.11864 2.4151 2.93066C2.5867 2.74268 2.79555 2.59249 3.02837 2.48965C3.26119 2.38681 3.51288 2.33358 3.7674 2.33334H6.5174C6.96226 2.32896 7.39354 2.4865 7.73085 2.77658C8.06815 3.06666 8.28847 3.4695 8.35073 3.91001C8.4668 4.79007 8.68206 5.65417 8.9924 6.48584C9.11573 6.81394 9.14242 7.17051 9.06931 7.51331C8.9962 7.85612 8.82636 8.17078 8.5799 8.42001L7.41573 9.58417C8.72066 11.8791 10.6208 13.7792 12.9157 15.0842L14.0799 13.92C14.3291 13.6736 14.6438 13.5037 14.9866 13.4306C15.3294 13.3575 15.686 13.3842 16.0141 13.5075C16.8457 13.8178 17.7098 14.0331 18.5899 14.1492C19.0352 14.212 19.4419 14.4363 19.7326 14.7794C20.0233 15.1225 20.1777 15.5605 20.1666 16.01Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span className="ml-2">
                        Call Now
                      </span>
                    </button> */}

                      </div>
                    </div>



                    <div className=" d-flex flex-wrap align-items-center justify-content-between mt-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <h3 className="fs-6" style={{ fontSize: '16px', color: '#000', fontWeight: 500 }}>About the Seller</h3>
                          <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.25" y="0.25" width="89.5" height="89.5" rx="44.75" fill="white" stroke="#959494" stroke-width="0.5" />
                          </svg>

                        </div>
                        <div className="seller-info ml-3">
                          <p style={{ fontSize: '14px', color: '#1D1D1D' }}>Shop no. 353, Bolton Plaza</p>
                          <p style={{ fontSize: '14px', color: '#1D1D1D' }}>Abbas Wholesale Store</p>
                        </div>
                      </div>
                      <button className="border rounded-pill mt-4 mt-sm-0" style={{
                        color: 'whitesmoke',
                        backgroundColor: '#1A78B4',
                        fontSize: '14px',
                        padding: '.5rem 3rem'
                      }}
                        onClick={() => navigate(`/companydetail?business=${v.uid}`)}
                      >Company Website</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <section className="section-box shop-template" id="get-quote-form">
              <div className="col-lg-6 mx-auto">
                <div className="card card-body">
                  <h4 className="title py-3 text-center" style={{ color: '#1A78B4' }}>Get Quote</h4>
                  <div className="form-group">
                    <input
                      style={{ color: "black" }}
                      className="form-control"
                      placeholder="Name of Product or Service"
                      type="text"
                      disabled
                      value={sheet[0].name}
                    />
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="Quantity"
                        type="number"
                        value={sheet[0].qty}
                        onChange={(e) => {
                          changeArayquote(0, { qty: e.target.value });
                        }}
                      />

                      <select
                        value={sheet[0].piece}
                        onChange={(e) => {
                          changeArayquote(0, { piece: e.target.value });
                        }}
                        className="custom-select form-control"
                      >
                        <option>Pieces</option>
                        <option>Litres</option>
                        <option>Tons</option>
                        <option>Gramms</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Requirement Detail"
                      type="text"
                      value={sheet[0].des}
                      onChange={(e) => {
                        changeArayquote(0, { des: e.target.value });
                        setMesg(generateMsg(v, sheet));
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <input
                        className="form-control"
                        placeholder="Price"
                        type="text"
                        value={sheet[0].price}
                        onChange={(e) => {
                          changeArayquote(0, { price: e.target.value });
                          setMesg(generateMsg(v, sheet));
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center my-2">
                    <button
                      className="btn px-5 text-light border rounded-pill"
                      style={{ backgroundColor: '#1A78B4' }}
                      onClick={() => {

                        SameUser();

                      }}
                    >
                      Get Quote
                    </button>
                    {alert2 ? (
                      <>
                        {" "}
                        <SweetAlert
                          success
                          confirmBtnText="Thanks !"
                          confirmBtnBsStyle="success"
                          timeout={2000}
                          title="Your Requirement Successfully Sent to the Seller"
                          onConfirm={() => {
                            setAlert2(!alert2);
                          }}
                          onCancel={() => {
                            setAlert2(!alert2);
                          }}
                          focusCancelBtn
                        >
                          <br />
                        </SweetAlert>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="container">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="gallery-image">
                      <div className="galleries">
                        <div className="detail-gallery">
                          <div className="product-image-slider">
                            <Carousel variant="dark">


                              <Carousel.Item>
                                <div>
                                  <img
                                    height="350px"

                                    src={v.img}
                                    alt="First slide"
                                    className="d-block w-100"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => openImage(v.img)}
                                  />
                                </div>

                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  height="350px"
                                  className="d-block w-100"
                                  src={v.img_a}
                                  alt="First slide"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => openImage(v.img_a)}
                                />

                              </Carousel.Item>

                              <Carousel.Item>
                                <img
                                  height="350px"
                                  className="d-block w-100"
                                  src={v.img_b}
                                  alt="First slide"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => openImage(v.img_b)}

                                />

                              </Carousel.Item>


                            </Carousel>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <h3 className="color-brand-3 mb-25">
                      {v.name}

                    </h3>
                    <a className="text-right" href={"/companydetail?business=" + v.uid}>
                      <button style={{ borderRadius: "20px" }} className="btn-primary mb-4 py-1 px-2" >Company Website</button>
                    </a>

                    <div className="border-bottom" />
                    <div className="box-product-price">
                      <h3 className="color-brand-3 price-main d-inline-block mr-10">
                        Price: {v.price}<br />
                        {v.pro_price != "" ? <>Discount Price :{v.pro_price} </> : <></>}

                      </h3>

                    </div>
                    <div className="product-description color-gray-900">
                      <div className="row">
                        <div className="col-lg-4">
                          <ul className="list-dot">
                            <li>SKU Code: {v.barcode} </li>
                            <li>Reliable Product</li>
                            <li>Minimum Order Quantity: {v.minimum_quantity}</li>
                            <li>Delivery Time {v.delivery}</li>

                          </ul>
                        </div>

                        <div className="col-lg-8">
                          <div>
                            <div className="card card-body">
                              <h4 className="title py-3 text-center">Get Quote</h4>
                              <div className="form-group">
                                <input
                                  style={{ color: "black" }}
                                  className="form-control"
                                  placeholder="Name of Product or Service"
                                  type="text"
                                  disabled
                                  value={sheet[0].name}
                                />
                              </div>
                              <div className="form-group">
                                <div className="input-group">
                                  <input
                                    className="form-control"
                                    placeholder="Quantity"
                                    type="number"
                                    value={sheet[0].qty}
                                    onChange={(e) => {
                                      changeArayquote(0, { qty: e.target.value });
                                    }}
                                  />

                                  <select
                                    value={sheet[0].piece}
                                    onChange={(e) => {
                                      changeArayquote(0, { piece: e.target.value });
                                    }}
                                    className="custom-select form-control"
                                  >
                                    <option>Pieces</option>
                                    <option>Litres</option>
                                    <option>Tons</option>
                                    <option>Gramms</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  placeholder="Requirement Detail"
                                  type="text"
                                  value={sheet[0].des}
                                  onChange={(e) => {
                                    changeArayquote(0, { des: e.target.value });
                                    setMesg(generateMsg(v, sheet));
                                  }}
                                />
                              </div>
                              <div className="row">
                                <div className="col-12 form-group">
                                  <input
                                    className="form-control"
                                    placeholder="Price"
                                    type="text"
                                    value={sheet[0].price}
                                    onChange={(e) => {
                                      changeArayquote(0, { price: e.target.value });
                                      setMesg(generateMsg(v, sheet));
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="text-center my-2">
                                <button
                                  className=" btn btn-primary px-5"
                                  onClick={() => {

                                    SameUser();

                                  }}
                                >
                                  Get Quote
                                </button>
                                {alert2 ? (
                                  <>
                                    {" "}
                                    <SweetAlert
                                      success
                                      confirmBtnText="Thanks !"
                                      confirmBtnBsStyle="success"
                                      timeout={2000}
                                      title="Your Requirement Successfully Sent to the Seller"
                                      onConfirm={() => {
                                        setAlert2(!alert2);
                                      }}
                                      onCancel={() => {
                                        setAlert2(!alert2);
                                      }}
                                      focusCancelBtn
                                    >
                                      <br />
                                    </SweetAlert>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>



              </div> */}

            </section>
            {/* <section className="section-box shop-template">
              <div className="container">
                <div className="pt-30 mb-10">
                  <ul className="nav nav-tabs nav-tabs-product" role="tablist">
                    <li>
                      <a
                        className="active"
                        href="#tab-description"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="tab-description"
                        aria-selected="true"
                      >
                        Description
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="tab-description"
                      role="tabpanel"
                      aria-labelledby="tab-description"
                    >
                      <div className="display-text-short">
                        <p>
                          {v.des}
                        </p>




                      </div>

                    </div>


                    <div className="border-bottom pt-30 mb-50" />
                    <h4 className="color-brand-3">Related Products</h4>
                    <div className="list-products-5 mt-20">
                      {allUID.map((v, i) => (
                        <>
                          <div className="card-grid-style-3">
                            <div className="card-grid-inner">

                              <div className="image-box">
                                <a href={"product?id=" + v.id + "&name=" + v.name}>
                                  <img
                                    src={v.img}
                                    alt="Ecom"
                                  />
                                </a>
                              </div>
                              <div className="info-right">
                                <a
                                  className="color-brand-3 font-sm-bold"
                                  href={"product?id=" + v.id + "&name=" + v.name}
                                >
                                  {v.name}
                                </a>

                                <div className="price-info">
                                  <strong className="font-lg-bold color-brand-3 price-main">
                                    {v.price}
                                  </strong>
                                </div>
                                <div className="mt-20 box-btn-cart">
                                  <a className="btn btn-cart" href={"product?id=" + v.id + "&name=" + v.name}>
                                    Get Quote
                                  </a>
                                </div>

                              </div>
                            </div>
                          </div>
                        </>
                      ))}

                    </div>
                    <div className="border-bottom pt-20 mb-40" />
                    <h4 className="color-brand-3">You may also like</h4>
                    <div className="list-products-5 mt-20">
                      {allSimilar.map((v, i) => (
                        <>
                          <div className="card-grid-style-3">
                            <div className="card-grid-inner">

                              <div className="image-box">
                                <a href={"product?id=" + v.id + "&name=" + v.name}>
                                  <img
                                    src={v.img}
                                    alt="Ecom"
                                  />
                                </a>
                              </div>
                              <div className="info-right">
                                <a
                                  className="color-brand-3 font-sm-bold"
                                  href={"product?id=" + v.id + "&name=" + v.name}
                                >
                                  {v.name}
                                </a>

                                <div className="price-info">
                                  <strong className="font-lg-bold color-brand-3 price-main">
                                    {v.price}
                                  </strong>
                                </div>
                                <div className="mt-20 box-btn-cart">
                                  <a className="btn btn-cart" href={"product?id=" + v.id + "&name=" + v.name}>
                                    Get Quote
                                  </a>
                                </div>

                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                  </div>
                </div>
              </div>
            </section> */}

          </>))}


        <section className="section-box mt-90 mb-50">
          <div className="container">
            <ul className="list-col-5">
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/delivery.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
                    <p className="font-sm color-gray-500">From all orders over $10</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/support.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                    <p className="font-sm color-gray-500">Shop with an expert</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/voucher.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                    <p className="font-sm color-gray-500">Refer a friend</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/return.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">
                      Return &amp; Refund
                    </h5>
                    <p className="font-sm color-gray-500">Free return over $200</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/secure.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Secure payment</h5>
                    <p className="font-sm color-gray-500">100% Protected</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className="section-box box-newsletter">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7 col-sm-12">
                <h3 className="color-white">
                  Subscrible &amp; Get <span className="color-warning">10%</span>{" "}
                  Discount
                </h3>
                <p className="font-lg color-white">
                  Get E-mail updates about our latest shop and{" "}
                  <span className="font-lg-bold">special offers.</span>
                </p>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12">
                <div className="box-form-newsletter mt-15">
                  <form className="form-newsletter">
                    <input
                      className="input-newsletter font-xs"
                      defaultValue=""
                      placeholder="Your email Address"
                    />
                    <button className="btn btn-brand-2">Sign Up</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="ModalFiltersForm"
          tabIndex={-1}
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content apply-job-form">
              <div className="modal-header">
                <h5 className="modal-title color-gray-1000 filters-icon">
                  Addvance Fillters
                </h5>
                <button
                  className="btn-close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body p-30">
                <div className="row">
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Brands</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">Apple</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Samsung</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Baseus</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Remax</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Handtown</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Elecom</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Razer</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Auto Focus</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Nillkin</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Logitech</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">ChromeBook</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Special offers</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">On sale</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">FREE shipping</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Big deals</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Shop Mall</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">1 business day</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">1–3 business days</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">in 1 week</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Shipping now</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Ordering options</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Accepts gift cards</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Customizable</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">Can be gift-wrapped</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Installment 0%</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
                    <ul className="list-checkbox">
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (5 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (4 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (3 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (2 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (1 star)
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-2">
                    <h6 className="color-gray-900 mb-0">Material</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Nylon (8)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Tempered Glass (5)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">
                            Liquid Silicone Rubber (5)
                          </span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Aluminium Alloy (3)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
                    <div>
                      <a className="btn btn-border mr-5" href="#">
                        Games
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Electronics
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Video
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Cellphone
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Indoor
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        VGA Card
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        USB
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Lightning
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Camera
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-start pl-30">
                <a className="btn btn-buy w-auto" href="#">
                  Apply Fillter
                </a>
                <a className="btn font-sm-bold color-gray-500" href="#">
                  Reset Fillter
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="ModalQuickview"
          tabIndex={-1}
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content apply-job-form">
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body p-30">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="gallery-image">
                      <div className="galleries-2">
                        <div className="detail-gallery">
                          <div className="product-image-slider-2">
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-1.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-2.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-3.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-4.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-5.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-6.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-7.jpg"
                                alt="product image"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="slider-nav-thumbnails-2">
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-1.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-2.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-3.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-4.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-5.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-6.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-7.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-tags">
                      <div className="d-inline-block mr-25">
                        <span className="font-sm font-medium color-gray-900">
                          Category:
                        </span>
                        <a className="link" href="#">
                          Smartphones
                        </a>
                      </div>
                      <div className="d-inline-block">
                        <span className="font-sm font-medium color-gray-900">
                          Tags:
                        </span>
                        <a className="link" href="#">
                          Blue
                        </a>
                        ,
                        <a className="link" href="#">
                          Smartphone
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="product-info">
                      <h5 className="mb-15">
                        SAMSUNG Galaxy S22 Ultra, 8K Camera &amp; Video, Brightest
                        Display Screen, S Pen Pro
                      </h5>
                      <div className="info-by">
                        <span className="bytext color-gray-500 font-xs font-medium">
                          by
                        </span>
                        <a
                          className="byAUthor color-gray-900 font-xs font-medium"
                          href="shop-vendor-list.html"
                        >
                          {" "}
                          Ecom Tech
                        </a>
                        <div className="rating d-inline-block">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <span className="font-xs color-gray-500 font-medium">
                            {" "}
                            (65 reviews)
                          </span>
                        </div>
                      </div>
                      <div className="border-bottom pt-10 mb-20" />
                      <div className="box-product-price">
                        <h3 className="color-brand-3 price-main d-inline-block mr-10">
                          $2856.3
                        </h3>
                        <span className="color-gray-500 price-line font-xl line-througt">
                          $3225.6
                        </span>
                      </div>
                      <div className="product-description mt-10 color-gray-900">
                        <ul className="list-dot">
                          <li>8k super steady video</li>
                          <li>Nightography plus portait mode</li>
                          <li>50mp photo resolution plus bright display</li>
                          <li>Adaptive color contrast</li>
                          <li>premium design &amp; craftmanship</li>
                          <li>Long lasting battery plus fast charging</li>
                        </ul>
                      </div>
                      <div className="box-product-color mt-10">
                        <p className="font-sm color-gray-900">
                          Color:
                          <span className="color-brand-2 nameColor">Pink Gold</span>
                        </p>
                        <ul className="list-colors">
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-1.jpg"
                              alt="Ecom"
                              title="Pink"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-2.jpg"
                              alt="Ecom"
                              title="Gold"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-3.jpg"
                              alt="Ecom"
                              title="Pink Gold"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-4.jpg"
                              alt="Ecom"
                              title="Silver"
                            />
                          </li>
                          <li className="active">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-5.jpg"
                              alt="Ecom"
                              title="Pink Gold"
                            />
                          </li>
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-6.jpg"
                              alt="Ecom"
                              title="Black"
                            />
                          </li>
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-7.jpg"
                              alt="Ecom"
                              title="Red"
                            />
                          </li>
                        </ul>
                      </div>
                      <div className="box-product-style-size mt-10">
                        <div className="row">
                          <div className="col-lg-12 mb-10">
                            <p className="font-sm color-gray-900">
                              Style:
                              <span className="color-brand-2 nameStyle">S22</span>
                            </p>
                            <ul className="list-styles">
                              <li className="disabled" title="S22 Ultra">
                                S22 Ultra
                              </li>
                              <li className="active" title="S22">
                                S22
                              </li>
                              <li title="S22 + Standing Cover">
                                S22 + Standing Cover
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-12 mb-10">
                            <p className="font-sm color-gray-900">
                              Size:
                              <span className="color-brand-2 nameSize">512GB</span>
                            </p>
                            <ul className="list-sizes">
                              <li className="disabled" title="1GB">
                                1GB
                              </li>
                              <li className="active" title="512 GB">
                                512 GB
                              </li>
                              <li title="256 GB">256 GB</li>
                              <li title="128 GB">128 GB</li>
                              <li className="disabled" title="64GB">
                                64GB
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="buy-product mt-5">
                        <p className="font-sm mb-10">Quantity</p>
                        <div className="box-quantity">
                          <div className="input-quantity">
                            <input
                              className="font-xl color-brand-3"
                              type="text"
                              defaultValue={1}
                            />
                            <span className="minus-cart" />
                            <span className="plus-cart" />
                          </div>
                          <div className="button-buy">
                            <a className="btn btn-cart" href="shop-cart.html">
                              Add to cart
                            </a>
                            <a className="btn btn-buy" href="shop-checkout.html">
                              Buy now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* Fullscreen Image Modal */}
      {selectedImage && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1050 }} // Increase z-index to 1050
          onClick={closeImage}
        >
          <img
            src={selectedImage}
            alt="fullscreen"
            className="img-fluid"
            style={{ maxHeight: '90%', maxWidth: '90%' }}
          />
          <button
            className="position-absolute top-0 end-0 m-3 btn btn-light"
            onClick={closeImage}
          >
            Close
          </button>
        </div>

      )}

    </>

  )
}

export default Product_Detail