import Nav from '../../../navbar';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Mainpage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [uid, setUid] = useState('');
  const uid2c = useRef('');
  const [array, setArray] = useState([]);
  const [view, setView] = useState(false); // Controls whether to display client data
  const [viewNew, setViewNew] = useState(false); 


  // Set the default base URL
  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://'+ window.location.hostname;

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
      // Handle the case where the user is not logged in
    } else {
      setUid(v[0].id);
    }
  }, []);

  const getDetail = async () => {

    if (phone.length < 10) {
      alert("Enter valid phone number with 10 digit");
      return; // Exit the function if the phone number is invalid
    }

    const data = new FormData();
    data.append('email', phone); // changed 'email' to 'phone'
    
    axios({
      method: 'post',
      url: '/pro/khata/khata_getdatabyemail.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log(s.data);
        if (s.data[0].code == "pass") {
          if (s.data[0].uid2c == uid) {
            alert("Something Went Wrong!!")
          } else {
            setPhone(s.data[0].phone);
            setAddress(s.data[0].add); // This may be empty
            setName(s.data[0].name);
            uid2c.current = s.data[0].uid2c;
            setEmail(s.data[0].email); // Populate email from the API
            setView(true); // Set to true to disable the inputs for this client
            setArray(s.data);
            setViewNew(false);
          }
        } else {
          setView(false); // If API fails or no client found, enable all inputs
          emptyInputs();
          setViewNew(true);
        }
      })
      .catch(s => {
        console.log('Error fetching data:', s);
        setView(false); // Reset view to false and enable inputs on failure
        emptyInputs();
      });
  };

  const saveOtherClient = async () => {
    const data = new FormData();
    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('pass', "passpass");
    data.append('city', "abc");
    data.append('address', address);
    data.append('val', 'reg_client');
    
    axios({
      method: 'post',
      url: '/pro/khata/manage_khata.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log("Client added:", s.data);
        if (s.data[0].code === "pass") {
          setPhone(s.data[0].phone);
          setAddress(s.data[0].add);
          setName(s.data[0].name);
          uid2c.current = s.data[0].uid2c;
          setArray(s.data);
          saveDataFromOtherClient(s.data);
        } else if (s.data[0].code === "already") {
          alert("Client already exists");
        }
      })
      .catch(s => {
        console.log('Error saving data:', s);
      });
  };

  const emptyInputs = async () => {
    setName('');
    setAddress('');
    setEmail('');
  }

  const saveDataFromOtherClient = async (arr) => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('cid', uid2c.current);
    data.append('array', JSON.stringify(arr));
    data.append('val', 'add_client');
    
    axios({
      method: 'post',
      url: '/pro/khata/manage_khata.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {

        console.log("client info", s.data);

        if (s.data[0].code == "empty") {

          alert('please enter Right email')

        }
        else if (s.data[0].code == "already") {
          alert('Client already Existed')
        } else if (s.data[0].code == "pass") {
          navigate('/khataclient');
        } else {
          alert("Something went wrong 1. Please try again.");
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const saveData = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('cid', uid2c);
    data.append('array', JSON.stringify(array));
    data.append('val', 'add_client');
    
    axios({
      method: 'post',
      url: '/pro/khata/manage_khata.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {

        console.log("client info", s.data);

        if (s.data[0].code == "empty") {

          alert('please enter Right email')

        }
        else if (s.data[0].code == "already") {
          alert('Client already Existed')
        } else if (s.data[0].code == "pass") {

          navigate('/khataclient');

        } else {
          alert("`Something` Went Wrong.. Please Try Again")
        }





      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  return (
    <div>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                <div className="card-title text-center">
                    <h3>New Client</h3>
                  </div>

                  <div className="card-body">
                    <div className="basic-elements">
                      <div className="row">
                        <div className="form-group col-12">
                          <label>Phone No.</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="inputGroupPrepend">+92</span>
                            </div>
                              <input
                                type="text"
                                placeholder="Phone No."
                                className="form-control"
                                value={phone}
                                onChange={(e) => {
                                  if (e.target.value.length <= 10) {
                                    setPhone(e.target.value);
                                    emptyInputs();
                                    setView(false); // Hide other fields until "Next" is clicked
                                    setViewNew(false); // Hide other fields until "Next" is clicked
                                  } else {
                                    alert("Enter valid phone number with 10 digit");
                                    
                                  }
                                }}
                              />
                            </div>
                        </div>
                      </div>
                      {(view || viewNew) && (
                        <div>
                          <div className="row">
                            <div className="col-6 form-group">
                              <label>Business Name</label>
                              <input
                                className="form-control"
                                value={name}
                                type="text"
                                onChange={(e) => setName(e.target.value)} // Added onChange handler
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label>Address</label>
                              <input
                                className="form-control"
                                value={address}
                                type="text"
                                onChange={(e) => setAddress(e.target.value)} // Added onChange handler
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label>Email</label>
                              <input
                                className="form-control"
                                value={email}
                                type="email"
                                onChange={(e) => setEmail(e.target.value)} // Existing onChange handler
                              />
                            </div>
                          </div>
                          <button onClick={view ? saveData : saveOtherClient} className="btn btn-primary my-4">Add Client</button>
                        </div>                      
                      )}
                      {!(view || viewNew) && (
                        <button onClick={getDetail} className="btn btn-primary my-4">Next</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mainpage;
