import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Nav from "../../../navbar";
import DataTable from "react-data-table-component";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function LedgerInvoicePage() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [uid, setUid] = useState("");
  const [searchText, setSearchText] = useState("");
  const [downloading, setDownloading] = useState({});
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [darw, setDarw] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Added for pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Added for pagination

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user && user[0]?.id) {
      setUid(user[0].id);
      fetchInvoiceData(user[0].id);
    }
  }, []);

  const fetchInvoiceData = (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("run", "uid");

    axios({
      method: "post",
      url: "/pro/khata/khata_invoice_view.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("InvoiceList",JSON.stringify(response.data))
          setInvoiceData(response.data);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch invoices:", error);
      });
  };

  const downloadInvoice = async (id, invoiceId, clientName) => {
    try {
      setButtonsDisabled(true);
      setDownloading({ [id]: true });

      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.top = "-9999px";
      iframe.style.width = "1024px"; // Set iframe width to match browser window
      iframe.style.height = "768px"; // Set iframe height to match browser window
      iframe.src = `/invoiceprint?id=${id}`;
      document.body.appendChild(iframe);

      iframe.onload = async () => {
        try {
          const iframeDocument = iframe.contentWindow.document;

          // Wait for the content to fully load (retained as is)
          const waitForContent = async () => {
            return new Promise((resolve) => {
              const interval = setInterval(() => {
                if (iframeDocument.querySelector('#invoice-loaded-marker')) { // Ensure the marker exists
                  clearInterval(interval);
                  resolve();
                }
              }, 100);
            });
          };

          await waitForContent(); // Retained logic
          const canvas = await html2canvas(iframeDocument.body, {
            scale: 2,
            windowWidth: iframeDocument.body.scrollWidth,
            windowHeight: iframeDocument.body.scrollHeight,
            y: 20,
          });

          const imageData = canvas.toDataURL("image/png");

          const link = document.createElement("a");
          link.href = imageData;
          link.download = `Invoice_${id}_${invoiceId}_${clientName}.png`;
          link.click();

          document.body.removeChild(iframe);
        } catch (renderError) {
          console.error("Failed to capture the full invoice as an image:", renderError);
        } finally {
          setButtonsDisabled(false);
          setDownloading({ [id]: false });
        }
      };
    } catch (error) {
      console.error("Failed to download the invoice as an image:", error);
    }
  };

  // Filtered data for search
  const filteredData = invoiceData.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  // Paginated data for the current page
  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (_, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
    },
    { name: "Invoice No.", selector: (row) => row.iid, sortable: true },
    { name: "Date", selector: (row) => row.invoicedate, sortable: true },
    { name: "Created For", selector: (row) => row.user[0]?.name, sortable: true },
    { name: "Contact No.", selector: (row) => row.user[0]?.phone, sortable: true },
    { name: "Invoice Email", selector: (row) => row.user[0]?.email, sortable: true },
    { name: "Comments", selector: (row) => row.comments },
    {
      name: "Amount",
      selector: (row) => new Intl.NumberFormat("en-PK").format(row.total || 0),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex" key={row.iid}> {/* Ensure a unique key */}
          <button
            className="btn btn-primary me-2"
            onClick={() => window.open(`/invoiceprint?id=${row.khata_id}`, "_blank")}
          >
            View
          </button>
          <button
            className="btn btn-info"
            onClick={() => downloadInvoice(row.khata_id, row.iid, row.user[0]?.name)}
            disabled={downloading[row.khata_id] || buttonsDisabled}
          >
            {downloading[row.khata_id] ? (
              <>
                Download <span className="spinner-border spinner-border-sm"></span>
              </>
            ) : (
              "Download"
            )}
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px' 
    },
  ];
  

  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{ overflow: "auto", display: "flex", flexDirection: "column", padding: "0px" }}
        className={
          darw
            ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
            : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
        }
      >
        <div className="container-fluid">
        <div className="mt-3 mb-3">
                    <h4> Invoices</h4>
                  </div>
          <div className="d-flex justify-content-between mt-3 mb-3">
          
            <input
              type="text"
              className="form-control w-25"
              placeholder="Search Invoices..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="btn-group">
              <Link to="/newinvoice">
                <button className="btn btn-primary mr-2">New Invoice</button>
              </Link>
              <Link to="/invoicedraft">
                <button className="btn btn-primary">Invoice Draft</button>
              </Link>
            </div>
          </div>
          <DataTable
  columns={columns}
  data={filteredData.map((row, index) => ({
    ...row,
    uniqueKey: `${row.iid}-${index}`, // Ensures uniqueness
  }))}
  keyField="uniqueKey" // Use this field for unique keys
  pagination
  paginationServer={false}
  paginationTotalRows={filteredData.length}
  paginationDefaultPage={currentPage}
  paginationRowsPerPageOptions={[10, 20, 30]}
  onChangePage={handlePageChange}
  onChangeRowsPerPage={handleRowsPerPageChange}
  highlightOnHover
  responsive
/>
        </div>
      </div>
    </div>
  );
}

export default LedgerInvoicePage;
