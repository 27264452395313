// utils.js
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from 'firebase/app';

export const SendWelcome = async (uid) => {
  

    const data = new FormData();
    data.append("userid", uid);
    data.append("typeid", "1");
    data.append("title", "New Login Activity ");
    data.append("body", "Your alahdeen account is logged in from Web Browser");
    data.append("status", "1");
    data.append("android", "Home");
    data.append("ios", "Home");
    data.append("web", "Homepage");
    data.append("senderid", uid);
    const navigate = useNavigate();

    axios({
      method: "post",
      url: "/pro/auth/sendnotification.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

      navigate('/')
      })
      .catch((s) => {
        console.log("here catch ", s);
        navigate('/')
      });
  };

  export const generateToken=()=>{

    const firebaseConfig = {
        apiKey: "AIzaSyCNThPGDzsQTuxrGZYBuG-H8yCK4CTg1Jo",
        authDomain: "alahdeen-24173.firebaseapp.com",
        databaseURL: "https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "alahdeen-24173",
        storageBucket: "alahdeen-24173.appspot.com",
        messagingSenderId: "298890559548",
        appId: "1:298890559548:web:6fda9c54ae5e295d868339",
        measurementId: "G-6Q2NLSNF9T"
      };
      const firebaseApp = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebaseApp);


    getToken(messaging, { vapidKey: "BHCoZmVKjdsI3vrieJcu2h0f5_dczoimUN6xMct50s5eC3wdOF4h_BfP8jVUVaZwzfQxyzcAwcp0oV5ws1AXrX0" })
    .then((currentToken) => {
      if (currentToken) {
  
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        return '';
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return ''
    });
  }

  export const redirectToLoginWithBackto=()=>{ 
    // Get the full path and query string after the domain
    const backto = window.location.pathname + window.location.search;
  
    // Redirect to the login page with the backto parameter
    window.location.href = `/login?backto=${encodeURIComponent(backto)}`;
  }

  export const navigateToBacktoOrDefault=(navigate)=>{ 
    // Parse the current URL to get the query parameters
    const params = new URLSearchParams(window.location.search);
    
    // Check if the 'backto' key exists
    const backto = params.get('backto');
    
    if (backto) {
      // Decode and navigate to the desired URL from 'backto'
      navigate(decodeURIComponent(backto));
    } else {
      // Navigate to the default home page
      navigate('/');
    }
  }