import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../../../navbar";
import DataTable from "react-data-table-component";
import SweetAlert from "react-bootstrap-sweetalert";

function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [index, setIndex] = useState();
  const [lead, setLead] = useState([]);
  const [alert, setAlert] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [darw, setDarw] = useState(true);
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user) {
      setUid(user[0].id);
      lead_view_all(user[0].id);
    }
  }, []);

  const checkvalidity = (lead_id) => {
    
    
    localStorage.setItem(
      'leadid',
      lead_id,
    );
    // navigate('/chat');
    navigate('/sendquote');
  }



  const lead_view_all = async id => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/sendlead.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => setLead(response.data))
      .catch((error) => console.error("Error fetching leads:", error));
  };

  const handleSearch = (data, query) => {
    if (!query) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  const columns = [
    {
      name: "#",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
      minWidth: "50px",
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={
            row.img?.length > 1
              ? row.img
              : "https://alahdeen.com/pro/product_img/image.jpg"
          }
          alt="Product"
          style={{ width: "100px", height: "auto" }}
        />
      ),
      hide: "sm", // Hide on small screens
    },
    {
      name: "Product Name",
      selector: (row) => row.name || "N/A",
      sortable: true,
      maxWidth: "140px",
      minWidth: "140px",
    },
    {
      name: "Price",
      selector: (row) => row.payment || "N/A",
      sortable: true,
      maxWidth: "10px",
      hide: "sm", // Hide on small screens
    },
    {
      name: "Discount %",
      selector: (row) => row.discount || "N/A",
      sortable: true,
      hide: "sm", // Hide on small screens
    },
    {
      name: "Qty",
      selector: (row) => row.qty || "N/A",
      sortable: true,
      maxWidth: "10px",
      hide: "md", // Hide on medium screens
    },
    {
      name: "Description",
      selector: (row) => row.des || "N/A",
      sortable: true,
      hide: "lg", // Hide on large screens
    },
    {
      name: "Delivery Time",
      selector: (row) => row.delivery || "N/A",
      sortable: true,
      hide: "md", // Hide on medium screens
    },
    {
      name: "Applicable Tax %",
      selector: (row) => row.app_tax || "N/A",
      sortable: true,
      hide: "md", // Hide on medium screens
    },
    {
      name: "Shipping Tax %",
      selector: (row) => row.ship_tax || "N/A",
      sortable: true,
      hide: "lg", // Hide on large screens
    },
    {
      name: "Date",
      selector: (row) => row.time || "N/A",
      sortable: true,
      hide: "md", // Hide on medium screens
    },
    {
      name: "Details",
      cell: (_, rowIndex) => (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setIndex(rowIndex);
            setAlert(true);
          }}
        >
          Details
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  

  const ExpandableRow = ({ data }) => (
    <div style={{ padding: "10px" }}>
      <p>
        <strong>Image:</strong>
        <br />
        <img
          src={
            data.img?.length > 1
              ? data.img
              : "https://alahdeen.com/pro/product_img/image.jpg"
          }
          alt="Product"
          style={{ width: "100px", height: "auto" }}
        />
      </p>
      <p>
        <strong>Product Name:</strong> {data.name || "N/A"}
      </p>
      <p>
        <strong>Price:</strong> {data.payment || "N/A"}
      </p>
      <p>
        <strong>Discount %:</strong> {data.discount || "N/A"}
      </p>
      <p>
        <strong>Qty:</strong> {data.qty || "N/A"}
      </p>
      <p>
        <strong>Description:</strong> {data.des || "N/A"}
      </p>
      <p>
        <strong>Delivery Time:</strong> {data.delivery || "N/A"}
      </p>
      <p>
        <strong>Applicable Tax %:</strong> {data.app_tax || "N/A"}
      </p>
      <p>
        <strong>Shipping Tax %:</strong> {data.ship_tax || "N/A"}
      </p>
      <p>
        <strong>Date:</strong> {data.time || "N/A"}
      </p>
    </div>
  );  

  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{ overflow: "auto", display: "flex", flexDirection: "column", padding: "0px" }}
        className={
          darw
            ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
            : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
        }
      >
        <div className="container-fluid">
        <div className="row mb-3">
        <h4 className="mt-3"> Send Quote Requests</h4>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={handleSearch(lead, searchText)}
          pagination
          highlightOnHover
          responsive
          expandableRows
          expandableRowsComponent={ExpandableRow}
        />
      </div>

      {alert && (
        <SweetAlert
          confirmBtnText="OK"
          confirmBtnBsStyle="success"
          title="Get Quote Details"
          onConfirm={() => setAlert(false)}
          focusCancelBtn
        >
          <p>
            <strong>Lead Id:</strong>{" "}
            {JSON.parse(lead[index].sheet)[0]?.id || "N/A"}
          </p>
          <p>
            <strong>Product Name:</strong>{" "}
            {JSON.parse(lead[index].sheet)[0]?.name || "N/A"}
          </p>
          <p>
            <strong>Product Qty:</strong>{" "}
            {JSON.parse(lead[index].sheet)[0]?.quantity || "N/A"}
          </p>
          <p>
            <strong>Product Price:</strong>{" "}
            {JSON.parse(lead[index].sheet)[0]?.price || "N/A"}
          </p>
          <p>
            <strong>Description:</strong>{" "}
            {JSON.parse(lead[index].sheet)[0]?.des || "N/A"}
          </p>
        </SweetAlert>
      )}
    </div>
    </div>
  );
}

export default Buyleads;
