import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "react-bootstrap";
import Navmain from './header';
// import Navmain from '../components/landingpage/navbar';
import axios from "axios";
import Footer from './footer';
import Whatsapp from '../components/landingpage/whatsapp';
import { FaTshirt } from "react-icons/fa";
import { MdFastfood } from "react-icons/md";
import { MdElectricBolt } from "react-icons/md";
import { FaBuilding } from "react-icons/fa6";
import { MdOutlineComputer } from "react-icons/md";
import { MdLocalPharmacy } from "react-icons/md";
import { FaIndustry } from "react-icons/fa6";
import { FaHospitalAlt } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS


import Image1 from '../images/1.png'
import Image2 from '../images/2.png'
import Image3 from '../images/3.png'


function Home() {
  const navigate = useNavigate();
  const [allcats, setAllcats] = useState([]);
  const [user, setUser] = useState([]);
  const [alert1, setAlert1] = useState();
  const [uid, setUid] = useState();
  const [lead, setLead] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [searched, setSearched] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trade, setTrade] = useState([]);
  const [city, setCity] = useState("Karachi");
  const [listcities, setCitiesList] = useState([]);

  const [main, setMainCat] = useState([]);
  const [error, seterror] = useState(false);
  const [cat, setCat] = useState("");
  // Set the default base URL
  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://' + window.location.hostname;

  const SideBarCategories = [
    {
      id: "a3d2fa1b-1c5b-4f3e-bf75-193f698a380d",
      name: "Apparel & Garments",
      icon: "FaTshirt",
      link: "/sub/apparel-garments"
    },
    {
      id: "b2cfe557-1fdb-4e16-8f85-b9cd6cc4b0f3",
      name: "Food & Beverages",
      icon: "MdFastfood",
      link: "/sub/food-beverages"
    },
    {
      id: "d51969f3-5638-47f9-a0a9-b1fd8e17a7b2",
      name: "Electronics & Electrical",
      icon: "MdElectricBolt",
      link: "/sub/electronics-electrical"
    },
    {
      id: "c2ae7730-0b4f-42a3-bf60-b27d0152903b",
      name: "Building Construction",
      icon: "FaBuilding",
      link: "/sub/building-construction"
    },
    {
      id: "fe879c9a-02a7-4bc0-9c78-2bfbcc4b5e74",
      name: "Computer & IT Solutions",
      icon: "MdOutlineComputer",
      link: "/sub/computer-it-solutions"
    },
    {
      id: "f703e174-0fe7-4e52-9f98-3e7e436bba34",
      name: "Drugs & Pharmaceuticals",
      icon: "MdLocalPharmacy",
      link: "/sub/drugs-pharmaceuticals"
    },
    {
      id: "e57d2e93-b178-45f4-bbb2-e69ab5151c34",
      name: "Industrial Plants & Machinery",
      icon: "FaIndustry",
      link: "/sub/industrial-plants-machinery"
    },
    {
      id: "bb89a3c8-61b9-4cd0-b5b7-0dbce491dd25",
      name: "Hospital & Diagnostics",
      icon: "FaHospitalAlt",
      link: "/sub/hospital-diagnostics"
    }
  ];

  const iconMap = {
    FaTshirt: <FaTshirt color='#d1d5db' />,
    MdFastfood: <MdFastfood color='#d1d5db' />,
    MdElectricBolt: <MdElectricBolt color='#d1d5db' />,
    FaBuilding: <FaBuilding color='#d1d5db' />,
    MdOutlineComputer: <MdOutlineComputer color='#d1d5db' />,
    MdLocalPharmacy: <MdLocalPharmacy color='#d1d5db' />,
    FaIndustry: <FaIndustry color='#d1d5db' />,
    FaHospitalAlt: <FaHospitalAlt color='#d1d5db' />
  };


  useEffect(() => {
    // getCities();
    getServercats()
    lead_view_all()
    Viewpromotion()
    ViewSearched()
    ViewTrending()
    Tradeshow()
    getMainCat()
    // const aa = localStorage.getItem("USER");



    const search = window.location.search;
    const params = new URLSearchParams(search);

    const spplier = params.get("name");

    ShowData(spplier);
    ShowBuyLeads();

    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);
      setUid(as[0].id);
      setUser(as);
    }
  }, []);


  function isNotFloatOrAlpha(value) {
    // Check if value is a float
    const isFloat = typeof value === 'number' && !Number.isInteger(value);

    // Check if value is an alphabetical letter
    const isAlpha = typeof value === 'string' && /^[a-zA-Z]$/.test(value);

    // Return true if neither condition is true
    return !isFloat && !isAlpha;
  }







  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
        console.log("maincat news", s.data)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const [sheet, setSheet] = useState(
    [{
      id: 1,
      name: "",
      qty: "",
      quantity: "",
      piece: "Pieces",
      price: "",
      des: "",
      status: "normal",
      cat: ""
    }
    ]);

  const getServercats = async () => {
    const data = new FormData();

    data.append("uid", "");


    axios({
      method: "post",
      url: "/mainsite/cat_set_by_market.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewpromotion = async () => {
    const data = new FormData();




    axios({
      method: "post",
      url: "/pro/promotional_products.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Promotion", s.data)
        setPromotion(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const ViewSearched = async () => {
    const data = new FormData();
    axios({
      method: "post",
      url: "/pro/mostsearch.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('searched', s.data);
        setSearched(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };




  const ViewTrending = async () => {
    const data = new FormData();

    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",
      url: "/pro/trending.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('trending', s.data);
        setTrending(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const Tradeshow = async () => {
    const data = new FormData();

    data.append("val", "last");


    axios({
      method: "post",
      url: "/pro/tradeshow/manage_trade.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('tradeshow', s.data);
        setTrade(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const add_lead = async (sheet, uid, c) => {

    const data = new FormData();

    data.append("uid", uid);
    data.append("sheet", JSON.stringify(sheet));
    data.append("cat", c);
    data.append("img", "");

    axios({
      method: "post",
      url: "/pro/lead_add.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        setAlert1(!alert1);
        navigate('/product-buy-request')
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });

  };
  const lead_view_all = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/lead_view_all.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        // console.log('here lead_view_all ', s);
      });
  };
  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };
  const [vendor, setVendor] = useState([])


  const ShowData = async (spplier) => {
    const data = new FormData();
    data.append("key", spplier);
    data.append("val", "supplier");
    axios({
      method: "post",
      url: "/pro/vender.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        console.log("Markets Data", s.data);
        setVendor(s.data)
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const [buyLeads, setBuyLeads] = useState([])

  const ShowBuyLeads = async () => {
    const data = new FormData();
    axios({
      method: "post",
      url: "/pro/get_buy_leads.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setBuyLeads(s.data)
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  return (
    <>
      <Navmain />
      <Whatsapp />
      <main className="main">
        <section className="pt-50 bg-home9 no-gutters px-md-4">
          <div className="container-fluid px-3">
            <div className='row equal-height d-flex justify-content-between'>
              <div className="col-lg-3 order-last order-lg-first d-none d-lg-block" style={{ borderRadius: '16px', boxShadow: '0px 0px 17px 6px #ccc', backgroundColor: '#fff' }}> {/*sidebar-border*/}
                <div className="mb-0 d-none d-lg-block" style={{ backgroundColor: '#fff', borderRadius: '16px', padding: '20px 8px 8px 8px' }}>
                  <div className="sidebar-head text-center mt-2" style={{ borderRadius: '16px' }}>
                    <h6 className="color-gray-900 sidebar-head-title py-3 fs-6 text-light">Product Categories</h6>
                  </div>
                  <div className="sidebar-content d-none d-lg-block" style={{ backgroundColor: '#fff', cursor: 'pointer', borderRadius: '16px', marginBottom: '16px', marginTop: '34px' }}>
                    {SideBarCategories.map(category => (
                      <span key={category.id} className='d-flex ml-2 mt-2' style={
                        {
                          fontSize: '14px',
                          lineHeight: '32px',
                          color: '#020617',
                          display: 'inline-block'
                        }
                      }>
                        {iconMap[category.icon] && <span className='mr-3'>{iconMap[category.icon]}</span>}
                        <a
                          onClick={() => {
                            localStorage.setItem("title", category.name);
                            window.location.assign(category.link);
                          }}
                          className='sidebar-font-size'
                        >
                          {category.name}
                        </a>
                      </span>
                    ))}
                   <Link to='/main'><span className='d-inline-block ml-4 mt-3 mb-0 fw-normal' style={{ color: '#FD9636', fontSize: '16px' }}>View All Categories</span></Link> 
                  </div>
                </div>
              </div>

              <div className='col-lg-6 header-border-styles d-lg-flex align-items-md-start'>
                <div className="mb-0 header-border-styles">
                  <div id="carouselExampleIndicators header-border-styles" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner" style={{ borderRadius: '16px' }}>
                      <div class="carousel-item active">
                        <a href="/sub/drugs-pharmaceuticals">
                          <img src={Image1} class="d-block w-100" alt="..." />
                        </a>
                      </div>
                      <div class="carousel-item">
                        <a href="/sub/drugs-pharmaceuticals">
                          <img src={Image2} class="d-block w-100" alt="..." />
                        </a>
                      </div>
                      <div class="carousel-item">
                        <a href="/sub/drugs-pharmaceuticals">
                          <img src={Image3} class="d-block w-100" alt="..." />
                        </a>
                      </div>
                      {/* <div class="carousel-item">
                        <a href="/sub/drugs-pharmaceuticals">
                          <img src="https://alahdeen.com/assets/images/banner/4.png" class="d-block w-100" alt="..." />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 order-last order-lg-last d-none d-lg-block" style={{ boxShadow: '0px 0px 17px 6px #ccc', borderRadius: '16px', background: '#fff' }}>
                <div className="mb-0">
                  {/* <div className="sidebar-content"> */}
                  <h4 className="color-gray-900 text-center mb-4 mt-5 fs-3">Get Quote</h4>
                  <div className='mt-5 px-3 d-flex flex-column'>
                    <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].name == "" ? "Error: Name of Product" : ""}</div>
                    <input
                      style={{ borderColor: error == false ? "transparent" : sheet[0].name == "" ? "red" : "transparent" }}
                      className="form-control border border-dark rounded"
                      placeholder="Name of Product"
                      type="text"
                      value={sheet[0].name}
                      onChange={(e) => {
                        changeArayquote(0, { name: e.target.value });
                      }}
                    />

                    {/* </div> */}

                    {/* <div>
                        <div className='row' >
                          <div className='col' style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].qty == "" ? "Error: Quantity" : Number(sheet[0].qty) < 1 ? "Error: Quantity" : ""}</div>
                          <div className='col' style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].piece == "" ? "Error: Pices Unit" : sheet[0].piece == "" ? "Error: Pices Unit" : ""}</div>
                        </div>
                      </div> */}

                    {/* <div className="form-group">
                        <div className="input-group"> */}
                    <input

                      className="form-control border border-dark rounded mt-2"
                      placeholder="Quantity"
                      type="number"
                      value={sheet[0].qty}
                      onChange={(e) => {
                        changeArayquote(0, { qty: e.target.value, quantity: e.target.value });
                      }}
                    />

                    {/* <div className="form-control"> */}
                    <select
                      style={{ borderColor: error == false ? "transparent" : sheet[0].piece == "" ? "red" : "transparent" }}
                      value={sheet[0].piece}
                      onChange={(e) => {
                        changeArayquote(0, { piece: e.target.value });
                      }}
                      className="py-2 px-2 border border-dark rounded mt-2"
                      aria-label="Select option"
                    >

                      <option>Units</option>
                      <option>Pieces</option>
                      <option>Grams</option>
                      <option>Kilograms</option>
                      <option>Tons</option>
                      <option>Litres</option>
                    </select>

                    {/* </div>
                        </div> */}

                    <select
                      value={cat}
                      onChange={ve => {
                        setCat(ve.target.value)
                      }}
                      className="py-2 px-2 border border-dark rounded mt-2">
                      <option style={{ color: '#9ca3af' }}>Select Main Category</option>

                      {main.map((v, i) => (
                        <option value={v.id} style={{ color: '#9ca3af' }}>{v.title}</option>
                      ))}
                    </select>

                    <input
                      className=" form-control border border-dark rounded mt-2"
                      placeholder="Approx Price Rs"
                      type="number"
                      value={sheet[0].price}
                      style={{ borderColor: error == false ? "transparent" : sheet[0].price == "" ? "red" : "transparent" }}
                      onChange={(e) => {
                        changeArayquote(0, { price: e.target.value });
                      }}
                    />
                    <textarea
                      className=" form-control border border-dark rounded mt-2"
                      placeholder="Requirement Description"
                      maxLength={100}

                      onChange={(e) => {
                        changeArayquote(0, { des: e.target.value });
                      }}
                    >{sheet[0].des}</textarea>
                  </div>






                  <div className="row" >

                    {/* <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : cat == "" ? "Error: Category" : ""}</div>
                        <div className="col-12 form-group"> */}

                    {/* <select

                            value={cat}
                            onChange={ve => {
                              setCat(ve.target.value)
                            }}
                            className="mx-1 form-control mt-2">
                            <option>Select Main Category</option>

                            {main.map((v, i) => (
                              <option value={v.id}>{v.title}</option>
                            ))}
                          </select> */}
                    {/* </div>
                      </div> */}

                    {/* <div className="row">
                        <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].price == "" ? "Error: Price" : Number(sheet[0].price) < 1 ? "Error: Price" : ""}</div> */}
                    {/* <div className="col-12 form-group"> */}
                    {/* <input
                            className="mx-1 form-control mt-2"
                            placeholder="Approx Price Rs"
                            type="number"
                            value={sheet[0].price}

                            style={{ borderColor: error == false ? "transparent" : sheet[0].price == "" ? "red" : "transparent" }}
                            onChange={(e) => {
                              changeArayquote(0, { price: e.target.value });
                            }}
                          /> */}
                    {/* </div> */}
                    {/* </div> */}

                    <div className="form-group px-3">
                      {/* <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].des == "" ? "Error: Requirement Description" : ""}</div> */}

                    </div>
                    <div
                      onClick={() => {
                        if (sheet[0].name === '') {

                          seterror(true)
                        } else if (sheet[0].qty === '' || Number(sheet[0].qty) < 1) {
                          seterror(true)

                        } else if (sheet[0].piece == '' || Number(sheet[0].piece) < 1) {
                          seterror(true)
                        } else if (cat == '') {
                          seterror(true)
                        } else if (sheet[0].des == "") {
                          seterror(true)
                        } else if (sheet[0].price == '' || Number(sheet[0].price) < 1) {
                          seterror(true)
                        } else {
                          add_lead(sheet, uid, cat)
                        }



                      }}
                      className="form-group text-center"
                    >
                      {/* <button className="btn btn-primary">Get quote</button> */}

                      <button
                        className="btn"
                        style={{ backgroundColor: '#45ACB3', color: 'white', marginTop: '20px', boxShadow: '3px 3px 10px 6px #ccc', border: '1px solid #45ACB3 !important' }}
                      // onClick={() => {
                      // setAlert(!alert);
                      // }}
                      >
                        Get Quote
                      </button>
                      {alert1 ? (
                        <>
                          {" "}
                          <SweetAlert
                            success
                            confirmBtnText="Thanks !"
                            confirmBtnBsStyle="primary"
                            timeout={2000}
                            title="Your Requirement Successfully Sent to the Seller"
                            onConfirm={() => {
                              setAlert1(!alert1);
                            }}
                            onCancel={() => {
                              setAlert1(!alert1);
                            }}
                            focusCancelBtn
                          >
                            <br />
                          </SweetAlert>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                  </div>
                  {/* </div> */}
                </div>



              </div>
            </div>





          </div>
        </section>

        {/* cat show 1 start */}
        <section className=" pt-4 bg-home9 no-gutters container-fluid">



          {allcats.map((v, i) => (
            <>

              {/* <header 
     
     
    className="section-heading heading-line">
      <h4 className="title-section text-uppercase">{v.title}</h4>
    </header> */}
              <div className="card mb-5 card-home-category" style={{ borderRadius: '25px' }}>
                <div className="row ">
                  <div className="d-none d-lg-block col-lg-3">
                    {i === 0 ? <>
                      <div
                        // rgb(203,220,223)
                        style={{
                          backgroundColor: "#D6EFF2"
                          , borderRadius: '16px'
                        }}
                        className="home-category-banner"
                      >
                        <div className='d-flex flex-column align-items-center mt-5'>
                          <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }} >{v.title}</h3>
                          {/* <h5>Medical Appliances</h5> */}
                          <Link
                            style={
                              {
                                padding: '.4rem 1rem',
                                border: '1px solid #2C99A1',
                                backgroundColor: '#2C99A1',
                                color: 'white',
                                boxShadow: '3px 3px 10px 6px #ccc'
                                , border: '1px solid #2C99A1'
                              }
                            }

                            to={"sub/" + v.bread_crumbs}
                            className="mt-3 border rounded d-flex align-items-center"
                            onClick={() => {
                              localStorage.setItem("title", v.title);
                            }}
                          >
                            <span className='mr-2'>
                              See All
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                              <g clip-path="url(#clip0_189_87)">
                                <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_189_87">
                                  <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Link>
                          <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                        </div>
                      </div>
                    </> :
                      i === 1 ?
                        <>
                          <div
                            // rgb(203,220,223)
                            style={{ backgroundColor: "#D6EFF2", borderRadius: '16px' }}
                            className="home-category-banner"
                          >
                            <div className='d-flex flex-column align-items-center mt-5'>
                              <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                              {/* <h5>Medical Appliances</h5> */}
                              <Link
                                style={
                                  {
                                    padding: '.4rem 1rem',
                                    border: '1px solid #2C99A1',
                                    backgroundColor: '#2C99A1',
                                    color: 'white',
                                    boxShadow: '3px 3px 10px 6px #ccc'
                                    , border: '1px solid #2C99A1'
                                  }
                                }

                                to={"sub/" + v.bread_crumbs}
                                className="mt-3 border rounded d-flex align-items-center"
                                onClick={() => {
                                  localStorage.setItem("title", v.title);
                                }}
                              >
                                <span className='mr-2'>
                                  See All
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                  <g clip-path="url(#clip0_189_87)">
                                    <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_189_87">
                                      <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </Link>
                              <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                            </div>
                          </div>
                        </>

                        :
                        i === 2 ? <>

                          <div
                            // rgb(203,220,223)
                            style={{
                              // eaeaed
                              backgroundColor: "#D6EFF2"
                              , borderRadius: '16px'
                            }}
                            className="home-category-banner"
                          >
                            <div className='d-flex flex-column align-items-center mt-5'>
                              <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                              {/* <h5>Medical Appliances</h5> */}
                              <Link
                                style={
                                  {
                                    padding: '.4rem 1rem',
                                    border: '1px solid #2C99A1',
                                    backgroundColor: '#2C99A1',
                                    color: 'white',
                                    boxShadow: '3px 3px 10px 6px #ccc'
                                    , border: '1px solid #2C99A1'
                                  }
                                }

                                to={"sub/" + v.bread_crumbs}
                                className="mt-3 border rounded d-flex align-items-center"
                                onClick={() => {
                                  localStorage.setItem("title", v.title);
                                }}
                              >
                                <span className='mr-2'>
                                  See All
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                  <g clip-path="url(#clip0_189_87)">
                                    <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_189_87">
                                      <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </Link>
                              <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                            </div>
                          </div>
                        </> :

                          i === 3 ? <>

                            <div
                              // rgb(203,220,223)
                              style={{
                                backgroundColor: "#D6EFF2"
                                , borderRadius: '16px'
                              }}
                              className="home-category-banner"
                            >
                              <div className='d-flex flex-column align-items-center mt-5'>
                                <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                                {/* <h5>Medical Appliances</h5> */}
                                <Link
                                  style={
                                    {
                                      padding: '.4rem 1rem',
                                      border: '1px solid #2C99A1',
                                      backgroundColor: '#2C99A1',
                                      color: 'white',
                                      boxShadow: '3px 3px 10px 6px #ccc'
                                      , border: '1px solid #2C99A1'
                                    }
                                  }

                                  to={"sub/" + v.bread_crumbs}
                                  className="mt-3 border rounded d-flex align-items-center"
                                  onClick={() => {
                                    localStorage.setItem("title", v.title);
                                  }}
                                >
                                  <span className='mr-2'>
                                    See All
                                  </span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <g clip-path="url(#clip0_189_87)">
                                      <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_189_87">
                                        <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Link>
                                <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                              </div>
                            </div>
                          </> :
                            i === 4 ? <>

                              <div
                                // rgb(203,220,223)
                                style={{
                                  backgroundColor: "#D6EFF2"
                                  , borderRadius: '16px'
                                }}
                                className="home-category-banner"
                              >
                                <div className='d-flex flex-column align-items-center mt-5'>

                                  <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                                  {/* <h5>Medical Appliances</h5> */}
                                  <Link
                                    style={
                                      {
                                        padding: '.4rem 1rem',
                                        border: '1px solid #2C99A1',
                                        backgroundColor: '#2C99A1',
                                        color: 'white',
                                        boxShadow: '3px 3px 10px 6px #ccc'
                                        , border: '1px solid #2C99A1'
                                      }
                                    }

                                    to={"sub/" + v.bread_crumbs}
                                    className="mt-3 border rounded d-flex align-items-center"
                                    onClick={() => {
                                      localStorage.setItem("title", v.title);
                                    }}
                                  >
                                    <span className='mr-2'>
                                      See All
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                      <g clip-path="url(#clip0_189_87)">
                                        <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_189_87">
                                          <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Link>
                                  <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                                </div>
                              </div>
                            </> :

                              i === 5 ? <>

                                <div
                                  // rgb(203,220,223)
                                  style={{
                                    backgroundColor: "#D6EFF2"
                                    , borderRadius: '16px'
                                  }}
                                  className="home-category-banner"
                                >
                                  <div className='d-flex flex-column align-items-center mt-5'>
                                    <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                                    {/* <h5>Medical Appliances</h5> */}
                                    <Link
                                      style={
                                        {
                                          padding: '.4rem 1rem',
                                          border: '1px solid #2C99A1',
                                          backgroundColor: '#2C99A1',
                                          color: 'white',
                                          boxShadow: '3px 3px 10px 6px #ccc'
                                          , border: '1px solid #2C99A1'
                                        }
                                      }

                                      to={"sub/" + v.bread_crumbs}
                                      className="mt-3 border rounded d-flex align-items-center"
                                      onClick={() => {
                                        localStorage.setItem("title", v.title);
                                      }}
                                    >
                                      <span className='mr-2'>
                                        See All
                                      </span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                        <g clip-path="url(#clip0_189_87)">
                                          <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_189_87">
                                            <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Link>
                                    <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                                  </div>
                                </div>
                              </> :
                                i === 6 ? <>

                                  <div
                                    // rgb(203,220,223)
                                    style={{
                                      backgroundColor: "#D6EFF2"
                                      , borderRadius: '16px'
                                    }}
                                    className="home-category-banner"
                                  >

                                    <div className='d-flex flex-column align-items-center text-center mt-5'>
                                      <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-center" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                                      {/* <h5>Medical Appliances</h5> */}
                                      <Link
                                        style={
                                          {
                                            padding: '.4rem 1rem',
                                            border: '1px solid #2C99A1',
                                            backgroundColor: '#2C99A1',
                                            color: 'white',
                                            boxShadow: '3px 3px 10px 6px #ccc'
                                            , border: '1px solid #2C99A1'
                                          }
                                        }

                                        to={"sub/" + v.bread_crumbs}
                                        className="mt-3 border rounded d-flex align-items-center"
                                        onClick={() => {
                                          localStorage.setItem("title", v.title);
                                        }}
                                      >
                                        <span className='mr-2'>
                                          See All
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                          <g clip-path="url(#clip0_189_87)">
                                            <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_189_87">
                                              <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </Link>
                                      <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                                    </div>
                                  </div>

                                </> : i === 5 ? <>

                                  <div
                                    // rgb(203,220,223)
                                    style={{
                                      backgroundColor: "#D6EFF2"
                                      , borderRadius: '16px'
                                    }}
                                    className="home-category-banner"
                                  >
                                    <div className='d-flex flex-column align-items-center text-center mt-5'>
                                      <h3 className="title-section mt-5 fs-4 font-bold mt-5 text-bold" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                                      {/* <h5>Medical Appliances</h5> */}
                                      <Link
                                        style={
                                          {
                                            padding: '.4rem 1rem',
                                            border: '1px solid #2C99A1',
                                            backgroundColor: '#2C99A1',
                                            color: 'white',
                                            boxShadow: '3px 3px 10px 6px #ccc'
                                            , border: '1px solid #2C99A1'
                                          }
                                        }

                                        to={"sub/" + v.bread_crumbs}
                                        className="mt-3 border rounded d-flex align-items-center"
                                        onClick={() => {
                                          localStorage.setItem("title", v.title);
                                        }}
                                      >
                                        <span className='mr-2'>
                                          See All
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                          <g clip-path="url(#clip0_189_87)">
                                            <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_189_87">
                                              <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </Link>
                                      <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                                    </div>
                                  </div>

                                </> : i === 7 ? <>

                                  <div
                                    // rgb(203,220,223)
                                    style={{
                                      backgroundColor: "#D6EFF2"
                                      , borderRadius: '16px'
                                    }}
                                    className="home-category-banner"
                                  >
                                    <div className='d-flex flex-column align-items-center text-center mt-5'>
                                      <h3 className="title-section mt-5 fs-4 font-bold mt-5" style={{ fontSize: "20px", color: '#425A8B' }}>{v.title}</h3>
                                      {/* <h5>Medical Appliances</h5> */}
                                      <Link
                                        style={
                                          {
                                            padding: '.4rem 1rem',
                                            border: '1px solid #2C99A1',
                                            backgroundColor: '#2C99A1',
                                            color: 'white',
                                            boxShadow: '3px 3px 10px 6px #ccc'
                                            , border: '1px solid #2C99A1'
                                          }
                                        }

                                        to={"sub/" + v.bread_crumbs}
                                        className="mt-3 border rounded d-flex align-items-center"
                                        onClick={() => {
                                          localStorage.setItem("title", v.title);
                                        }}
                                      >
                                        <span className='mr-2'>
                                          See All
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                                          <g clip-path="url(#clip0_189_87)">
                                            <path d="M0.83252 1.05499L0.832521 5.20832V8.94834C0.832521 9.58834 1.60585 9.90834 2.05919 9.45501L5.51252 6.00166C6.06586 5.44832 6.06586 4.54832 5.51252 3.99499L4.19919 2.68166L2.05919 0.54166C1.60585 0.0949936 0.83252 0.414994 0.83252 1.05499Z" fill="white" />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_189_87">
                                              <rect width="6" height="10" fill="white" transform="translate(0.379883)" />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </Link>
                                    </div>
                                    <img src={v.image_path} style={{ height: "300px", width: "100%" }} className="img-bg" />
                                  </div>

                                </> :

                                  <>

                                  </>

                    }


                  </div>
                  <div className="col-12 col-lg-9">
                    <ul className="row no-gutte0rs text-center">
                      <h1
                        style={{ color: 'rgb(87, 83, 78)', fontSize: "20px" }}
                        className="title-section mt-5 fs-1 fs-md-6 mb-4 mt-md-4 mb-md-3 d-block d-lg-none">{v.title} </h1>
                      {v.code == "sub" ? (
                        <>
                          {v.child.slice(0, 8).map((vv, ii) => (
                            <>
                              <li key={`new.dev.${ii}`} className="col-6 col-lg-3 col-md-4">
                                <a
                                  onClick={() => {
                                    localStorage.setItem("title", vv.title);
                                  }}
                                  href={"sub/" + vv.bread_crumbs}
                                  className="item item-homepage-card"
                                >
                                  <div className="card-body homepage-product-card-item-text">
                                    <img

                                      style={{ height: "200px", width: "200px" }}
                                      className="text-center item-homepage-card-img"
                                      src={vv.image_path}
                                    />
                                    <h6

                                      style={{ color: "black" }}
                                      className="mt-2 item-homepage-card-text"
                                    >
                                      {vv.title}
                                    </h6>
                                  </div>
                                </a>
                              </li>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          {v.child.slice(0, 8).map((vv, ii) => (
                            <>
                              <li key={`home.dev.${ii}`} className="col-6 col-lg-3 col-md-4">
                                <a
                                  href={"product?id=" + vv.id + "&s=" + vv.name}
                                  className="item"
                                >
                                  <center>
                                    <div className="card-body">
                                      <img
                                        style={{ height: "100px", width: "100px" }}
                                        className="text-center "
                                        src={vv.img}
                                      />
                                      <h6
                                        style={{ color: "black" }}
                                        className="mt-5 text-center"
                                      >
                                        {vv.title}
                                      </h6>
                                      <figcaption className="info-wrap">
                                        <Button
                                          variant="primary"
                                          className="my-2 mx-4"
                                        >
                                          Get Quote
                                        </Button>
                                      </figcaption>
                                    </div>
                                  </center>
                                </a>
                              </li>
                            </>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="row">
            <div className="text-center">
              <button
                onClick={() => {
                  navigate("/main");
                }}
                style={{ borderRadius: "20px", backgroundColor: 'rgb(69, 172, 179)', border: '1px solid rgb(69, 172, 179)', color: 'white', outline: 'none', boxShadow: 'rgb(204, 204, 204) 0px 0px 17px 6px' }}
                className="px-3 py-2"
              >All Categories
                <i className="ti-arrow-right mx-3" style={{ color: 'white' }}> </i>
              </button>
            </div>
          </div>
        </section>

        {/* cat show 1 end */}




        <div className="bg-home9">
          <section className="section-box mt-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 mb-20">
                  <div className="block-sale-1">
                    <div className="row">
                      <div className="col-lg-8 col-sm-8 col-8">

                        <span className="font-sm text-uppercase color-brand-3">
                          Most Viewed
                        </span>
                        <h3 className="mb-10">Apparel & Garments</h3>
                        <a className="btn btn-brand-2 btn-arrow-right" href="apparel-garments">
                          View More
                        </a>
                      </div>
                      <div className="col-lg-4 col-sm-4 col-4">
                        <img src="/assetsss/imgs/page/homepage7/sale1.png" alt="Ecom" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-20">
                  <div className="block-sale-1 bg-4">
                    <div className="row">
                      <div className="col-lg-8 col-sm-8 col-8">
                        <span className="font-sm text-uppercase color-brand-3">
                          Trending
                        </span>
                        <h3 className="mb-10">Sports & Good Products</h3>
                        <a className="btn btn-brand-2 btn-arrow-right" href="/sub/sports-good-products">
                          View More
                        </a>
                      </div>
                      <div className="col-lg-4 col-sm-4 col-4">
                        <img src="/assetsss/imgs/page/homepage7/sale2.png" alt="Ecom" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-20">
                  <div className="block-sale-1 bg-10">
                    <div className="row">
                      <div className="col-lg-8 col-sm-8 col-8">
                        <strong className="font-sm color-danger font-bold text-uppercase">
                          HOT ITEMS
                        </strong>
                        <h3 className="mb-10">Food & Beverages</h3>
                        <a className="btn btn-brand-2 btn-arrow-right" href="/sub/food-beverages">
                          View More
                        </a>
                      </div>
                      <div className="col-lg-4 col-sm-4 col-4">
                        <img src="/assetsss/imgs/page/homepage7/sale3.png" alt="Ecom" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>


        {/* <section className="section-box mt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box-slider-item">
                  <div className="head">
                    <h5>Most Searched Products</h5>
                  </div>
                  <div className="content-slider">
                    <div className="box-swiper">
                      <div className="swiper-container swiper-best-seller">
                        <div className="swiper-wrapper pt-5">
                          <div className="swiper-slide">
                            {searched.slice(0, 4).map((v, i) => (
                              <>
                                <a href={"/product?id=" + v.pid + "&s=" + v.title}>
                                  <div className="card-grid-style-2 card-grid-none-border hover-up">
                                    <div className="image-box">
                                      <a>
                                        <img
                                          src={v.img}
                                          alt="Ecom"
                                        />
                                      </a>
                                    </div>
                                    <div className="info-right">
                                      <span className="font-xs color-gray-500">{v.title}</span>
                                      <br />

                                      <div className="rating">
                                        <img
                                          src="/assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="/assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="/assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="/assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="/assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <span className="font-xs color-gray-500">
                                          {" "}
                                          (65)
                                        </span>
                                      </div>
                                      <div className="price-info">
                                        <strong className="font-lg-bold color-brand-3 price-main">
                                          {v.price}
                                        </strong>

                                      </div>
                                    </div>
                                  </div>
                                </a>

                              </>))}
                          </div>



                        </div>
                      </div>
                      <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-bestseller" />
                      <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-bestseller" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box-slider-item">
                  <div className="head">
                    <h5>Featured products</h5>
                  </div>
                  <div className="content-slider">
                    <div className="box-swiper">
                      <div className="swiper-container swiper-featured">
                        <div className="swiper-wrapper pt-5">
                          <div className="swiper-slide">
                            {promotion.slice(0, 4).map((v, i) => (
                              <>
                                <a href={"/product?id=" + v.pid + "&s=" + v.title}>
                                  <div className="card-grid-style-2 card-grid-none-border hover-up">
                                    <div className="image-box">

                                      <img
                                        src={v.img}
                                        alt="Ecom"
                                      />

                                    </div>
                                    <div className="info-right">
                                      <span className="font-xs color-gray-500">{v.title}</span>
                                      <br />

                                      <div className="rating">
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <span className="font-xs color-gray-500">
                                          {" "}
                                          (65)
                                        </span>
                                      </div>
                                      <div className="price-info">
                                        <strong className="font-lg-bold color-brand-3 price-main">
                                          {v.price}
                                        </strong>
                                        <span class="color-gray-500 price-line">{v.pro_price}</span>
                                      </div>
                                    </div>
                                  </div>
                                </a>

                              </>))}
                          </div>
                        </div>
                      </div>
                      <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-featured" />
                      <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-featured" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box-slider-item">
                  <div className="head">
                    <h5>Trending Products</h5>
                  </div>
                  <div className="content-slider">
                    <div className="box-swiper">
                      <div className="swiper-container swiper-mostviewed">
                        <div className="swiper-wrapper pt-5">
                          <div className="swiper-slide">
                            {trending.slice(0, 4).map((v, i) => (
                              <>
                                <a href={"/product?id=" + v.pid + "&s=" + v.title}>
                                  <div className="card-grid-style-2 card-grid-none-border hover-up">
                                    <div className="image-box">
                                      <span className="label bg-brand-2">-17%</span>

                                      <img
                                        src={v.img}
                                        alt="Ecom"
                                      />

                                    </div>
                                    <div className="info-right">
                                      <span className="font-xs color-gray-500">{v.title}</span>
                                      <br />

                                      <div className="rating">
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <img
                                          src="assetsss/imgs/template/icons/star.svg"
                                          alt="Ecom"
                                        />
                                        <span className="font-xs color-gray-500">
                                          {" "}
                                          (65)
                                        </span>
                                      </div>
                                      <div className="price-info">
                                        <strong className="font-lg-bold color-brand-3 price-main">
                                          {v.price}
                                        </strong>

                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </>))}
                          </div>
                        </div>
                      </div>
                      <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-mostviewed" />
                      <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-mostviewed" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section> */}

        <div className='bg-home9 pt-100'>
          <div className='container overflow-hidden'>
            <div className='row gx-3'>
              <div className='col-md-6'>
                <div className="container-fluid my-4 border rounded">
                  <div class="row">
                    <div class="col-lg-7 d-flex justify-content-between w-100 p-3">
                      <h5 className='ml-3' style={{ color: '#425A8B', fontSize: '20px', fontWeight: 700 }}>Buy Leads</h5>

                      <div className='d-flex'>
                        {/* Controls */}
                        <a
                          // className="carousel-control-prev"
                          href="#imageCarousel"
                          role="button"
                          data-slide="prev"
                        >
                          <span className='text-dark d-inline-block p-1 border rounded'>
                            <MdKeyboardArrowLeft />
                          </span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          // className="carousel-control-next"
                          href="#imageCarousel"
                          role="button"
                          data-slide="next"
                        >
                          <span className='text-dark d-inline-block p-1 border rounded ml-1'>
                            <MdOutlineKeyboardArrowRight />
                          </span>
                          <span className="sr-only ">Next</span>
                        </a>
                      </div>
                    </div>
                    <div class="col-lg-5">

                    </div>
                  </div>
                  <div id="imageCarousel" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row">
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          {/* Second Slide */}
                          {
                            buyLeads.slice(15, 19).map((v, i) => (<>
                              <div className="col-6 col-sm-3">
                                <img style={{ borderRadius: "5px", padding: "2px" }} src={(v.image ?? "http://alahdeen.com/assets/images/logos/logo.png")} className="d-block w-100" alt="Image 1" />
                                <p className="text-center mt-2" style={{ color: '#425A8B', fontSize: '14px', lineHeight: '1.3', fontWeight: 500 }}>{(v.name || "").substring(0, 20)}</p>
                              </div>
                            </>))}
                          {/* Add more items similarly */}
                        </div>
                      </div>
                      {/* Add more slides as needed */}
                      <div className="carousel-item">
                        <div className="row">
                          {/* Second Slide */}
                          {
                            buyLeads.slice(11, 15).map((v, i) => (<>
                              <div className="col-6 col-sm-3">
                                <img style={{ borderRadius: "5px", padding: "2px" }} src={(v.image ?? "http://alahdeen.com/assets/images/logos/logo.png")} className="d-block w-100" alt="Image 1" />
                                <p className="text-center mt-2" style={{ color: '#425A8B', fontSize: '14px', lineHeight: '1.3', fontWeight: 500 }}>{(v.name || "").substring(0, 20)}</p>
                              </div>
                            </>))}
                          {/* Add more items similarly */}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className="container-fluid my-4 border rounded">
                  <div class="row">
                    <div class="p-3 col-lg-7 w-100 d-flex justify-content-between">
                      <h5 className='ml-3' style={{ color: '#425A8B', fontSize: '20px', fontWeight: 700 }}>Recent Sellers</h5>
                      <div className='d-flex'>
                        {/* Controls */}
                        <a
                          // className="carousel-control-prev"
                          href="#imageCarousel2"
                          role="button"
                          data-slide="prev"
                        >
                          <span className='text-dark d-inline-block p-1 border rounded'>
                            <MdKeyboardArrowLeft />
                          </span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          // className="carousel-control-next"
                          href="#imageCarousel2"
                          role="button"
                          data-slide="next"
                        >
                          <span className='text-dark d-inline-block p-1 border rounded ml-1'>
                            <MdOutlineKeyboardArrowRight />
                          </span>
                          <span className="sr-only ">Next</span>
                        </a>
                      </div>
                    </div>
                    <div class="col-lg-5">

                    </div>
                  </div>
                  {/* <h2 className="text-center mb-4">Recent  Seller</h2> */}
                  <div id="imageCarousel2" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row">
                          {/* First Slide */}
                          {
                            vendor.slice(8, 12).map((v, i) => (<>
                              <div className="col-6 col-sm-3">
                                <img style={{ borderRadius: "5px", padding: "2px", backgroundColor: 'white' }} src={(v.image ?? "http://alahdeen.com/assets/images/logos/logo.png")} className="d-block w-100" alt="Image 1" />
                                <p className="text-center mt-2" style={{ color: '#425A8B', fontSize: '14px', lineHeight: '1.3', fontWeight: 500 }}>{(v.name || "").substring(0, 20)}</p>
                              </div>
                            </>))}
                          {/* Add more items similarly */}
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          {/* Second Slide */}
                          {
                            vendor.slice(13, 19).map((v, i) => (<>
                              <div className="col-6 col-sm-2">
                                <img style={{ borderRadius: "5px", padding: "2px" }} src={(v.cat_image ? "http://localhost/pro/cat/images/" + v.cat_image : "http://alahdeen.com/assets/images/logos/logo.png")} className="d-block w-100" alt="Image 1" />
                                <p className="text-center mt-2" style={{ color: '#425A8B', fontSize: '14px', lineHeight: '1.3', fontWeight: 500 }}>{(v.name || "").substring(0, 20)}</p>
                              </div>
                            </>))}
                          {/* Add more items similarly */}
                        </div>
                      </div>
                      {/* Add more slides as needed */}
                    </div>
                    {/* Controls */}

                  </div>
                </div>







              </div>
            </div>
          </div>
        </div>


        {/* <section className="section-box mt-90 mb-50">
          <div className="container">
            <ul className="list-col-5 pl-0">
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/delivery.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">45000+ Products</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/support.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/voucher.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/return.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">
                      Live Chatbot
                    </h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="item-list">
                  <div className="icon-left">
                    <img src="assetsss/imgs/template/secure.svg" alt="Ecom" />
                  </div>
                  <div className="info-right">
                    <h5 className="font-lg-bold color-gray-100">Buy Leads</h5>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section> */}

        <div
          className="modal fade"
          id="ModalQuickview"
          tabIndex={-1}
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content apply-job-form">
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body p-30">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="gallery-image">
                      <div className="galleries-2">
                        <div className="detail-gallery">
                          <div className="product-image-slider-2">
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-1.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-2.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-3.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-4.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-5.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-6.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-7.jpg"
                                alt="product image"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="slider-nav-thumbnails-2">
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-1.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-2.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-3.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-4.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-5.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-6.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-7.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-tags">
                      <div className="d-inline-block mr-25">
                        <span className="font-sm font-medium color-gray-900">
                          Category:
                        </span>
                        <a className="link" href="#">
                          Smartphones
                        </a>
                      </div>
                      <div className="d-inline-block">
                        <span className="font-sm font-medium color-gray-900">
                          Tags:
                        </span>
                        <a className="link" href="#">
                          Blue
                        </a>
                        ,
                        <a className="link" href="#">
                          Smartphone
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="product-info">
                      <h5 className="mb-15">
                        SAMSUNG Galaxy S22 Ultra, 8K Camera &amp; Video, Brightest
                        Display Screen, S Pen Pro
                      </h5>
                      <div className="info-by">
                        <span className="bytext color-gray-500 font-xs font-medium">
                          by
                        </span>
                        <a
                          className="byAUthor color-gray-900 font-xs font-medium"
                          href="shop-vendor-list.html"
                        >
                          {" "}
                          Ecom Tech
                        </a>
                        <div className="rating d-inline-block">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <span className="font-xs color-gray-500 font-medium">
                            {" "}
                            (65 reviews)
                          </span>
                        </div>
                      </div>
                      <div className="border-bottom pt-10 mb-20" />
                      <div className="box-product-price">
                        <h3 className="color-brand-3 price-main d-inline-block mr-10">
                          $2856.3
                        </h3>
                        <span className="color-gray-500 price-line font-xl line-througt">
                          $3225.6
                        </span>
                      </div>
                      <div className="product-description mt-10 color-gray-900">
                        <ul className="list-dot">
                          <li>8k super steady video</li>
                          <li>Nightography plus portait mode</li>
                          <li>50mp photo resolution plus bright display</li>
                          <li>Adaptive color contrast</li>
                          <li>premium design &amp; craftmanship</li>
                          <li>Long lasting battery plus fast charging</li>
                        </ul>
                      </div>
                      <div className="box-product-color mt-10">
                        <p className="font-sm color-gray-900">
                          Color:
                          <span className="color-brand-2 nameColor">Pink Gold</span>
                        </p>
                        <ul className="list-colors">
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-1.jpg"
                              alt="Ecom"
                              title="Pink"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-2.jpg"
                              alt="Ecom"
                              title="Gold"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-3.jpg"
                              alt="Ecom"
                              title="Pink Gold"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-4.jpg"
                              alt="Ecom"
                              title="Silver"
                            />
                          </li>
                          <li className="active">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-5.jpg"
                              alt="Ecom"
                              title="Pink Gold"
                            />
                          </li>
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-6.jpg"
                              alt="Ecom"
                              title="Black"
                            />
                          </li>
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-7.jpg"
                              alt="Ecom"
                              title="Red"
                            />
                          </li>
                        </ul>
                      </div>
                      <div className="box-product-style-size mt-10">
                        <div className="row">
                          <div className="col-lg-12 mb-10">
                            <p className="font-sm color-gray-900">
                              Style:
                              <span className="color-brand-2 nameStyle">S22</span>
                            </p>
                            <ul className="list-styles">
                              <li className="disabled" title="S22 Ultra">
                                S22 Ultra
                              </li>
                              <li className="active" title="S22">
                                S22
                              </li>
                              <li title="S22 + Standing Cover">
                                S22 + Standing Cover
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-12 mb-10">
                            <p className="font-sm color-gray-900">
                              Size:
                              <span className="color-brand-2 nameSize">512GB</span>
                            </p>
                            <ul className="list-sizes">
                              <li className="disabled" title="1GB">
                                1GB
                              </li>
                              <li className="active" title="512 GB">
                                512 GB
                              </li>
                              <li title="256 GB">256 GB</li>
                              <li title="128 GB">128 GB</li>
                              <li className="disabled" title="64GB">
                                64GB
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="buy-product mt-5">
                        <p className="font-sm mb-10">Quantity</p>
                        <div className="box-quantity">
                          <div className="input-quantity">
                            <input
                              className="font-xl color-brand-3"
                              type="text"
                              defaultValue={1}
                            />
                            <span className="minus-cart" />
                            <span className="plus-cart" />
                          </div>
                          <div className="button-buy">
                            <a className="btn btn-cart" href="shop-cart.html">
                              Add to cart
                            </a>
                            <a className="btn btn-buy" href="shop-checkout.html">
                              Buy now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ========================================================= */}

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="mb-0">
                <div class="sidebar-content mx-5 pt-3">
                  <h4 class="color-gray-900 text-center mt-5 mb-5">Get A Quote</h4>
                  <div class='mt-5'>
                    <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].name == "" ? "Error: Name of Product" : ""}</div>
                    {/* <div className="quote-form mt-1"> */}
                    <input
                      style={{ borderColor: error == false ? "transparent" : sheet[0].name == "" ? "red" : "transparent" }}
                      className="form-control border border-dark border border-dark"
                      placeholder="Name of Product"
                      type="text"
                      value={sheet[0].name}

                      onChange={(e) => {
                        changeArayquote(0, { name: e.target.value });
                      }}
                    />

                    {/* </div> */}

                    {/* <div>
                        <div className='row' >
                          <div className='col' style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].qty == "" ? "Error: Quantity" : Number(sheet[0].qty) < 1 ? "Error: Quantity" : ""}</div>
                          <div className='col' style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].piece == "" ? "Error: Pices Unit" : sheet[0].piece == "" ? "Error: Pices Unit" : ""}</div>
                        </div>
                      </div> */}

                    {/* <div className="form-group">
                        <div className="input-group"> */}
                    <input

                      className="form-control mt-2 quote-form-select-clr border border-dark"
                      placeholder="Quantity"
                      type="number"
                      value={sheet[0].qty}
                      onChange={(e) => {
                        changeArayquote(0, { qty: e.target.value, quantity: e.target.value });
                      }}
                    />

                    {/* <div className="form-control"> */}
                    <select
                      style={{ borderColor: error == false ? "transparent" : sheet[0].piece == "" ? "red" : "transparent" }}
                      value={sheet[0].piece}
                      onChange={(e) => {
                        changeArayquote(0, { piece: e.target.value });
                      }}
                      className="form-control mt-2 border border-dark"
                    >

                      <option >Units</option>
                      <option>Pieces</option>
                      <option>Grams</option>
                      <option>Kilograms</option>
                      <option>Tons</option>
                      <option>Litres</option>
                    </select>

                    {/* </div>
                        </div> */}

                    <select
                      value={cat}
                      onChange={ve => {
                        setCat(ve.target.value)
                      }}
                      className="form-control mt-2 quote-form-select-clr border border border-dark">
                      <option className='quote-form-select-clr'>Select Main Category</option>
                      {main.map((v, i) => (
                        <option value={v.id} className='quote-form-select-clr'>{v.title}</option>
                      ))}
                    </select>
                    <input
                      className="form-control mt-2 border border-dark"
                      placeholder="Approx Price Rs"
                      type="number"
                      value={sheet[0].price}

                      style={{ borderColor: error == false ? "transparent" : sheet[0].price == "" ? "red" : "transparent" }}
                      onChange={(e) => {
                        changeArayquote(0, { price: e.target.value });
                      }}
                    />
                  </div>




                  <div className="row" >

                    {/* <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : cat == "" ? "Error: Category" : ""}</div>
                        <div className="col-12 form-group"> */}

                    {/* <select

                            value={cat}
                            onChange={ve => {
                              setCat(ve.target.value)
                            }}
                            className="mx-1 form-control mt-2">
                            <option>Select Main Category</option>

                            {main.map((v, i) => (
                              <option value={v.id}>{v.title}</option>
                            ))}
                          </select> */}
                    {/* </div>
                      </div> */}

                    {/* <div className="row">
                        <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].price == "" ? "Error: Price" : Number(sheet[0].price) < 1 ? "Error: Price" : ""}</div> */}
                    {/* <div className="col-12 form-group"> */}
                    {/* <input
                            className="mx-1 form-control mt-2"
                            placeholder="Approx Price Rs"
                            type="number"
                            value={sheet[0].price}

                            style={{ borderColor: error == false ? "transparent" : sheet[0].price == "" ? "red" : "transparent" }}
                            onChange={(e) => {
                              changeArayquote(0, { price: e.target.value });
                            }}
                          /> */}
                    {/* </div> */}
                    {/* </div> */}

                    <div className="form-group">
                      {/* <div style={{ fontSize: "12px", color: "red" }}>{error == false ? "" : sheet[0].des == "" ? "Error: Requirement Description" : ""}</div> */}
                      <textarea

                        className=" form-control mt-2 border border-dark"
                        placeholder="Requirement Description"
                        maxLength={100}

                        onChange={(e) => {
                          changeArayquote(0, { des: e.target.value });
                        }}
                      >{sheet[0].des}</textarea>
                    </div>
                    <div
                    data-bs-dismiss="modal"
                      onClick={() => {
                        if (sheet[0].name === '') {

                          seterror(true)
                        } else if (sheet[0].qty === '' || Number(sheet[0].qty) < 1) {
                          seterror(true)

                        } else if (sheet[0].piece == '' || Number(sheet[0].piece) < 1) {
                          seterror(true)
                        } else if (cat == '') {
                          seterror(true)
                        } else if (sheet[0].des == "") {
                          seterror(true)
                        } else if (sheet[0].price == '' || Number(sheet[0].price) < 1) {
                          seterror(true)
                        } else {
                          add_lead(sheet, uid, cat);
                        }



                      }}
                      className="form-group text-center"
                    >
                      {/* <button className="btn btn-primary">Get quote</button> */}

                      <button
                        style={{
                          backgroundColor: '#45ACB3',
                          border: '2px solid #45ACB3',
                          color: 'white',
                          padding: '10px 20px',
                          fontSize: '16px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          outline: 'none',
                          transition: 'all 0.3s ease',
                          boxShadow: 'rgb(204, 204, 204) 0px 0px 17px 6px'

                          // boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
                        }}
                        class="btn quote-floating-btn"
                      // onClick={() => {
                      // setAlert(!alert);
                      // }}
                      >
                        Get Quote
                      </button>
                      {/* {alert1 ? (
                      <>
                        {" "}
                        <SweetAlert
                          success
                          confirmBtnText="Thanks !"
                          confirmBtnBsStyle="primary"
                          timeout={2000}
                          title="Your Requirement Successfully Sent to the Seller"
                          onConfirm={() => {
                            setAlert1(!alert1);
                          }}
                          onCancel={() => {
                            setAlert1(!alert1);
                          }}
                          focusCancelBtn
                        >
                          <br />
                        </SweetAlert>
                      </>
                    ) : (
                      <></>
                    )} */}
                    </div>

                  </div>
                </div>
              </div>
              {/* <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                ...
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div> */}
            </div>
          </div>

          {/* <div class="col-lg-3 sidebar-border order-last order-lg-last"> */}




          {/* </div> */}
        </div>
        {/*  */}

      </main>

      <button
        style={{
          bottom: '90px',
          zIndex: 1200,
          right: '30px',
          backgroundColor: '#45ACB3',
          border: '2px solid #45ACB3',
          color: 'white',
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          cursor: 'pointer',
          outline: 'none',
          transition: 'all 0.3s ease',
          boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
        }}
        className="btn quote-floating-btn position-fixed border rounded-pill" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Get A Quote
      </button>

      <Footer />
    </>
  )
}

export default Home