import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import SearchInput from "../../main/SearchInput"; // Import the new component

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function Nav() {

  const navigate = useNavigate();

  const [user, setUser] = useState([]);
  const [val, setval] = useState("product");
  const [key, setKey] = useState("");
  const [listcities, setCitiesList] = useState([]);
  const [allcats, setAllcats] = useState([]);
  const [notf, setNotf] = useState([]);
  const [userType, setUserType] = useState("");
  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://' + window.location.hostname;
  const [inputSearch, setInputSearch] = useState(""); // State to hold dynamic search value




  const logout = async () => {
    localStorage.removeItem("USER");
    navigate("/login");

  };

  const getServercats = async () => {
    const data = new FormData();

    data.append("id", "0");
    axios({
      method: "post",

      url: "/mainsite/suball.php",

      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("all cats nav br",s.data)
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const Notfication = async (uid) => {
    const data = new FormData();
    data.append("val", "viewbyid");
    data.append("uid", uid);
    axios({
      method: "post",
      url: "/pro/notification/manage_notification.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("notfication abc", s.data);
        setNotf(s.data)

      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const loginuser = async (k) => {
    const data = new FormData();
    data.append("status", "key");
    data.append("key", k);
    axios({
      method: "post",
      url: "mainsite/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(s.data);
        if (s.data[0].msg === "pass") {
          localStorage.setItem("USER", JSON.stringify(s.data));
          setUser(s.data);
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const updateSelection = () => {

    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const searchParams = url.searchParams;

    // Determine the default value for the select box
    let defaultValue = "product";
    if (searchParams.has("product")) {
      defaultValue = "product";
      setKey(searchParams.get("product")); // Set input value
    } else if (searchParams.has("supplier")) {
      defaultValue = "company";
      setKey(searchParams.get("supplier")); // Set input value
    } else if (pathname.includes("/supplier")) {
      defaultValue = "supp";
      setKey(searchParams.get("name") || ""); // Set input value
    } else if (pathname.includes("/sub/")) {
      const dynamicValue = pathname.split("/sub/")[1]; // Extract the value after "/sub/"
      defaultValue = "cat";
      //setInputSearch(dynamicValue || ""); // Update the state with the dynamic value
      // Triggering the onSearch after setting the value
      handleOnSearchCat(dynamicValue || "", allcats); // Pass the dynamic value and items (allcats) to onSearch
    }

    // Set the select box value
    setval(defaultValue);
  }

  useEffect(() => {
    getCities();
    getServercats();

    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);
      Notfication(as[0].id)


      console.log("sss", as);
      setUser(as);
      setUserType(as[0].roll)
    } else {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const key = params.get("a");

      if (key === null) {
        // console.log("key is null");
      } else {
        // console.log("value ", key);
        loginuser(key);
      }
    }
    updateSelection();
  }, []);



  const items = [
    { name: 'Punjab' },
    { name: 'Khyber Pakhtunkhwa' },
    { name: 'Sindh' },
    { name: 'Balochistān' },
    { name: 'Gilgit-Baltistan' }
  ]

  const handleOnSearch = (string, results) => {
    // console.log(string, results)

  }

  const handleOnSelect = (item) => {
    // console.log("finally selected",item.name)
    localStorage.setItem("title", item.name);
    window.location.href = "/search?c=" + item.name + "&s=" + item.name + "";
  }
  const handleOnSearchCat = (string, results) => {
    // console.log(string, results)

  }

  const handleOnSelectCat = (item) => {

    localStorage.setItem("title", item.title);
    window.location.href = "/sub/" + item.bread_crumbs;
  }

  const handleOnSearch_Pro = (string, results) => {
    // console.log(string, results)

  }

  const handleOnSelect_Pro = (item) => {

    localStorage.setItem("keycity", item.title);
    window.location.href = "/search?c=" + item.name + "&s=" + item.name + "";
  }


  const getCities = async () => {
    const data = new FormData();

    axios({
      method: "post",
      url: "/pro/auth/allcity_search.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setCitiesList(s.data)
      })
      .catch((s) => {

      });
  };

  return (
    <div>

      <header className="section-header">
        <section className="header-main border-bottom " >
          <div className="container">

            <div className="row align-items-center">
              <div
                onClick={() => {
                  navigate("/");
                }}
                className="col-xl-2 col-lg-3 col-md-12 col-sm-4"
              >
                <a href="/">
                  <img height={70} src="assets/images/logos/logo.png" /> </a>
              </div>
              <div className="col-xl-6 col-lg-5 col-md-6 col-sm-8">
                <div className="row">
                  <div style={{ marginTop: "4px", }} className="col-4">
                    <select
                      className="form-control form-control-lg form-select"
                      style={{ borderRadius: "0.5rem" }}
                      value={val}
                      onChange={(v) => {

                        setKey("");

                        if (v.target.value == "a") {
                        } else {
                          setval(v.target.value);
                        }
                      }}>
                      <option value="product"> Product </option>
                      <option value="company"> Supplier's Product </option>
                      <option value="supp"> Supplier's </option>
                      <option value="cat"> Category </option>
                      {/* <option value="pro"> Provinces </option>
                    <option value="city"> City </option> */}
                    </select>
                  </div>


                  <div className="col-4" style={{ zIndex: 2, }} aria-haspopup="true" aria-expanded="false" >
                    {
                      val == "city" ?
                        <ReactSearchAutocomplete
                          items={listcities}
                          onSearch={handleOnSearch}
                          onSelect={handleOnSelect}
                          autoFocus
                        />
                        : val == "cat" ?
                          <>
                            <ReactSearchAutocomplete
                              style={"fo"}
                              items={allcats}
                              onSearch={handleOnSearchCat}
                              inputSearchString={inputSearch} // Pass the dynamic value to the search box
                              onSelect={handleOnSelectCat}
                              autoFocus

                            />

                            {/* <div className="dropdown">
  <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    search
  </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
 {
      allcats.map((v)=>(

        <button className="dropdown-item" type="button" onClick={(e)=>{ 
           localStorage.setItem("title", v.title);
        location.href = "/sub?id=" + v.id + "&s=" + v.title;}}> {v.name} </button>
      ))
    }
  </div>
</div> */}

                          </>
                          : val == "pro" ? <ReactSearchAutocomplete
                            items={items}
                            onSearch={handleOnSearch_Pro}
                            onSelect={handleOnSelect_Pro}
                            autoFocus
                          /> : val == "company" ? (
                            <SearchInput
                              placeholder="Supplier Name"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          ) : val == "supp" ? (
                            <SearchInput
                              placeholder="Supplier's"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          ) : (
                            <SearchInput
                              placeholder="Product Name"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          )
                    }
                  </div>
                  <div className="col-2">

                    {
                      val == "cat" ? <>

                      </> :

                        val == "company" ?
                          <>
                            <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                              if (key.length < 4) {
                                alert("search minimum 4 characters!")

                              } else {
                                localStorage.setItem("title", key);
                                window.location.href = "/search?supplier=" + key;
                              }
                            }}> Search </button>
                          </> : val == "supp" ? <>

                            <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                              if (key.length < 4) {
                                alert("search minimum 4 characters!")

                              } else {
                                localStorage.setItem("title", key);
                                window.location.href = "/supplier?name=" + key;
                              }
                            }}> Find Now </button>

                          </>
                            :
                            <>
                              <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                                if (key.length < 4) {
                                  alert("search minimum 4 characters!")

                                } else {
                                  localStorage.setItem("title", key);
                                  window.location.href = "/search?product=" + key;
                                }
                              }}> Search </button></>
                    }



                  </div>
                </div>



              </div>



              {user.length == 0 ? (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="widgets-wrap float-md-right">
                      <div className="widget-header mr-3">
                        <div
                          onClick={() => {
                            navigate("/login");
                          }}
                          className="form-group text-center"
                        >
                          <button className="btn btn-primary"> Login </button>
                        </div>
                      </div>
                      <div className="widget-header mr-3">
                        <a href="/signup">
                          <div className="form-group text-center">
                            <button className="btn btn-primary">Signup</button>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="widgets-wrap float-md-right">
                      <div

                        className="widget-header mr-3">
                        <a href="/seller" className="widget-view">
                          <div className="icon-area text-center">
                            <i
                              style={{ color: "blue" }}
                              className="ti-wallet"
                            ></i>
                            {/* <span className="notify">1</span> */}
                          </div>{" "}
                          <small className="text">
                            {" "}
                            {userType == "" ?
                              <> <strong>Start Sell </strong></>

                              : <> <strong>Sell </strong></>}

                          </small>
                        </a>
                      </div>








                      <div
                        style={{ cursor: "pointer" }}
                        className="widget-header mr-3">

                        <div class="dropdown show">
                          <a class=" dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="widget-view">
                              <div className="icon-area text-center">
                                <i
                                  style={{ color: "blue" }}
                                  className="fa fa-bell"
                                ></i>

                              </div>
                              <small className="text">

                                <strong>Notification</strong>
                              </small>
                            </span>
                          </a>

                          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ overflow: "scroll", height: "500px", width: "300px" }}>

                            {/* 
  <a class="dropdown-item" >4444</a> 
  <a class="dropdown-item" >4444</a> 
  <a class="dropdown-item" >4444</a> 
  <a class="dropdown-item" >4444</a> 
   */}


                            {
                              notf.map((v) => (
                                <>

                                  <spane class="dropdown-item" >
                                    <h6><div className="icon-area text-center">
                                      <i

                                        className="fa fa-bell"
                                      ></i>

                                    </div> {v.title}</h6>
                                    <p>{v.body}</p>
                                    <span class="border-bottom"></span>
                                  </spane>

                                </>
                              ))
                            }





                          </div>
                        </div>


                      </div>
                      <div className="widget-header mr-3">
                        <a href="/buyer" className="widget-view">
                          <div

                            className="icon-area text-center">
                            <i
                              style={{ color: "black" }}
                              className="ti-user"
                            ></i>


                          </div>
                          <small className="text">
                            <strong> {user[0].name} </strong>{" "}
                          </small>
                        </a>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          logout();
                        }}
                        className="widget-header "
                      >
                        <div className="icon-area text-center">
                          <i
                            style={{ color: "blue" }}
                            className="ti-power-off"
                          ></i>
                        </div>
                        <small className="text my-1">
                          {" "}
                          <b>Logout </b>{" "}
                        </small>
                      </div>

                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

        </section>
        <nav className="navbar navbar-main navbar-expand-lg border-bottom">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#main_nav"
              aria-controls="main_nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav">

                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item ">
                  <a className="nav-link font-weight-bold" href="/">
                    Home
                  </a>
                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1">
                  <a className="nav-link  font-weight-bold" href="/buyer">
                    Buyer
                  </a>
                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1">
                  <a className="nav-link  font-weight-bold" href="/seller">
                    Seller
                  </a>
                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/">
                    Services
                  </a>

                </li>

                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/inventory.html">
                    Inventory
                  </a>

                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/office.html">
                    Office
                  </a>

                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/ewallet.html">
                    E-Wallet
                  </a>

                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/warehouse.html">
                    Warehouse
                  </a>

                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/adon.html">
                    Advertise With Us
                  </a>

                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/logistics.html">
                    Logistics
                  </a>

                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/aboutus.html">
                    About Us
                  </a>
                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/blogs/">
                    Blogs
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Nav;
