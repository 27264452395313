import { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import Nav from "../../../navbar2";

function Buyleads() {
  const navigate = useNavigate();
  const [buylead, setBuylead] = useState([]);
  const [uid, setUid] = useState('');
  const [status, setStatus] = useState('');
  const [darw, setDarw] = useState(true);

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'Quantity',
      selector: row => row.quantity,
      sortable: true
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true
    },
    {
      name: 'Description',
      selector: row => row.des,
      sortable: true
    }
  ];

  // const filteredData = [].filter(item =>
  //   item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   item.country.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  const v = JSON.parse(localStorage.getItem("USER"));

  // Set the default base URL
  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://'+ window.location.hostname;

  useEffect(() => {
    if (v === null) {
    } else {
      setUid(v[0].id);
      buyleads_buyer(v[0].id);

    }
  }, []);

  const buyleads_buyer = async (id) => {
    const data = new FormData();
    data.append("uid", v[0].id);

    axios({
      method: "post",
      url: "/pro/lead_view_uid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((s) => {
      console.log("buyleads-new-List", s.data);
      const a = s.data;
      // Parsing the sheet data
      const mergedSheet = a.reduce((acc, obj) => {
        const sheetData = JSON.parse(obj.sheet); // Parse the sheet data from the string
        return acc.concat(sheetData); // Concatenate the parsed data
    }, []);
    
    console.log('new_buyer_leads', mergedSheet);
      // const datass = s.data.map(item => {
      //   const result = JSON.parse(item.sheet[0]);
      //   console.log('new_buyer_leads1', item.sheet);
      //   return result;
      // });
      // const datass = JSON.parse(s?.data[0]?.sheet);
      setBuylead(mergedSheet);
    })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const Sold = async (id) => {
    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/buyer_lead_status_update.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Active Status Check List ", s.data);
        const a = s.data;
        //setBuylead("");
        buyleads_buyer(uid);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const Active = async (id) => {
    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/generalleadstatus.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Active Status Check List ", s.data);
        const a = s.data;
        //setBuylead("");
        buyleads_buyer(uid);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />

      <div
        style={{ overflowY: "auto" }}
        className={darw ? ["responsive-table", "mobile-table"].join(' ') : ["table-full-width", "mobile-full-width"].join(' ')}>
        <span style={{ color: '#57534e', marginBottom: '22px', fontSize: '28px' }}>Product Buy Requests</span>

        {/* Search input */}
        {/* <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px', padding: '5px', fontSize: '16px' }}
      /> */}

        {/* DataTable component */}
        <DataTable
          // title="Users List"
          columns={columns}
          data={buylead}
          pagination
          highlightOnHover
          pointerOnHover
          striped
          selectableRows
        />
      </div>


      {/* ===========================  Second Table  ============================== */}


      <div
        style={{ overflowY: "auto" }}
        className={darw ? ["responsive-table", "mobile-table"].join(' ') : ["table-full-width", "mobile-full-width"].join(' ')}>
        <span style={{ color: '#57534e', marginBottom: '22px', fontSize: '28px' }}>Purchased Buy Requests</span>

        {/* Search input */}
        {/* <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px', padding: '5px', fontSize: '16px' }}
      /> */}

        {/* DataTable component */}
        <DataTable
          // title="Users List"
          columns={columns}
          data={[]}
          pagination
          highlightOnHover
          pointerOnHover
          striped
          selectableRows
        />
      </div>

      {/* <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">

            <section id="main-content">

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Product Buy Requests </h4>

                  </div>



                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Approx Price</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>No. Of Request</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buylead.length == 0 ? (
                            <>


                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>

                            </>
                          ) : (
                            <></>
                          )}

                          {buylead.map((v, i) => (
                            <>
                              {v.status == "" ?
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{JSON.parse(v.sheet)[0].name}</td>
                                  <td>{JSON.parse(v.sheet)[0].qty}</td>
                                  <td>{JSON.parse(v.sheet)[0].piece}</td>
                                  <td>{JSON.parse(v.sheet)[0].price}</td>
                                  <td>{JSON.parse(v.sheet)[0].des}</td>
                                  <td>
                                    <p onClick={() => { Active(v.id) }} style={{ color: "red", cursor: "pointer" }}>
                                      {v.status == "" ? <u>Active</u> : <>Sold</>}
                                    </p>
                                  </td>

                                  <td>

                                    <div style={{ cursor: "pointer" }} className="widget-header mr-3">
                                      <a onClick={() => {
                                        localStorage.setItem(
                                          'leadid',
                                          v.id,
                                        );
                                        navigate('/requestno')
                                      }} className="widget-view">
                                        <div className="icon-area">
                                          <i style={{ color: '#0077b6' }} className="fa fa-bell"></i>

                                          <span className="notify">{v.count}</span>
                                        </div>
                                        <small className="text">
                                          <strong>Requests</strong>{' '}
                                        </small>
                                      </a>
                                    </div>


                                  </td>




                                  <td>{v.time}</td>
                                </tr> : <></>
                              }
                            </>

                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="main-content">

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Purchased Buy Requests </h4>

                  </div>



                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Approx Price</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Request</th>

                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buylead.length == 0 ? (
                            <>


                              <tr >
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>

                            </>
                          ) : (
                            <></>
                          )}

                          {buylead.map((v, i) => (
                            <>
                              {v.status == "sold" ?
                                <tr style={{ backgroundColor: "#caf0f8" }}>
                                  <td>{i + 1}</td>
                                  <td>{JSON.parse(v.sheet)[0].name}</td>
                                  <td>{JSON.parse(v.sheet)[0].qty}</td>
                                  <td>{JSON.parse(v.sheet)[0].piece}</td>
                                  <td>{JSON.parse(v.sheet)[0].price}</td>
                                  <td>{JSON.parse(v.sheet)[0].des}</td>
                                  <td>
                                    <p onClick={() => { Sold(v.id) }} style={{ color: "red" }}>
                                      {v.status == "" ? <u>Active</u> : <>Sold</>}
                                    </p>
                                  </td>
                                  <td>

                                    <div style={{ cursor: "pointer" }} className="widget-header mr-3">
                                      <a onClick={() => {
                                        localStorage.setItem(
                                          'leadid',
                                          v.id,
                                        );
                                        navigate('/requestno')
                                      }} className="widget-view">
                                        <div className="icon-area">
                                          <i style={{ color: '#0077b6' }} className="fa fa-eye"></i>

                                        </div>
                                        <small className="text">
                                          <strong>Requests</strong>{' '}
                                        </small>
                                      </a>
                                    </div>
                                  </td>
                                  <td>{v.time}</td>
                                </tr> : <></>
                              }
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div> */}
    </div>
  );
}
export default Buyleads;
