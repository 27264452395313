import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginImage from '../images/login.png';
import Logo from '../images/logo.png';
import { navigateToBacktoOrDefault, SendWelcome, generateToken } from "./utils"; // Import the SendWelcome, handleClick function from the shared utility file

function Login() {
  const [permission, setPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://' + window.location.hostname;

  const [token, setToken] = useState('');
  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else {
      setPermission(Notification.permission);
    }

    // Correctly invoke `generateToken` and set the token
    const fetchToken = async () => {
      const currentToken = await generateToken();
      if (currentToken) {
        setToken(currentToken);
      } else {
        console.log('No registration token available.');
      }
    };

    fetchToken();




  }, []);

  const handleClick = (id) => {
    if (permission === 'granted') {
      SendWelcome(id);
    } else if (permission === 'denied') {
      // alert('Permission denied.')
      console.log('Permission denied.');
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Permission granted.');
          SendWelcome(id);
        }
      });
    }
  };
  const [uid, setUid] = useState('')
  const [email, setEmail] = useState("");

  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [error, seterror] = useState("");

  const loginNow = async (e) => {
    setIsLoading(true); // Start loading
    e.preventDefault();
    console.log("email :", email, " : pass ", pass, "token -", token)
    const data = new FormData();
    data.append("email", email);
    data.append("pass", pass);
    data.append("status", "login");
    data.append("did", "web");
    data.append("dtype", "web");
    data.append("token", token);

    axios({
      method: "post",
      url: "/pro/auth/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data)
        setIsLoading(false);
        if (s.data[0].msg === "pass") {
          localStorage.setItem("USER", JSON.stringify(s.data));
          setUid(s.data[0].user)
          // SendWelcome(s.data[0].user);
          // handleClick(s.data[0].user)
          navigateToBacktoOrDefault(navigate);
          console.log(s.data)
        } else if (s.data[0].msg === "block") {
          alert("Sorry! Your account is Disabled. Please, contact Admin");
        } else if (s.data[0].msg === "otp") {
          navigate("/otp");
        } else {
          alert("Invalid Phone or Password! Please try again.");
        }
      })
      .catch((s) => {
        setIsLoading(false); // Stop loading
        console.log("here catch ", s);
      });
  }

  const text_type = (input) => {
    // Regular expression to check if the string contains only alphabetic characters
    const regex = /^[A-Za-z\s]+$/;

    // Test the input against the regular expression
    return regex.test(input);

  }


  return (
    <div
      style={{
        // backgroundImage: `../images/login.png`,
        //backgroundColor: "dodgerblue",
        // height: "100vh",
        width: '100%',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: 'relative'
      }}
    >
      <div >
        <div className="container-fluid" style={{padding: '0px'}}>
          <div className="row">
            <div className="col-6 d-none d-lg-block"
              style={{

                // maxHeight: '100vh',
                // position: 'sticky'
              }}
            >
              <img src={LoginImage}
                style={{
                  width: '100%', /* Ensure the image takes full width of the container */
                  height: '100%', /* Ensure the image takes full height */
                  objectFit: 'cover'
                }}
              //  className="w-100 h-100"
              />
            </div>
            <div className="col-12 col-lg-6"

            >
              <div className="login-content">
                <div style={{ opacity: 0.9 }} className="login-form">
                  {/* <center>
                    <img

                      onClick={() => {
                        navigate("/");
                      }}
                      style={{

                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      src={Logo}
                      height={100}
                    />
                  </center> */}
                  <h4 style={{ fontSize: '22px', color: '#1D2630', marginTop: "30px", letterSpacing: '1.4px' }} className="font-bold">Login with Phone Number</h4>

                  <div className={error == "phone" ? "form-group col-12 has-error" : "form-group col-12"}

                  >
                    <div class="col-auto">
                      <label className="font-size-12">Phone No.</label>
                      <div class="input-group border border-dark rounded">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="inputGroupPrepend"> +92 </span>
                        </div>
                        <input type="text" placeholder="Phone No." aria-describedby="inputGroupPrepend" className="form-control custom-placeholder" value={email}
                          onChange={(v) => {
                            let newValue = v.target.value;

                            newValue = newValue.replace(/[.-]/g, "");
                            if (newValue.charAt(0) === "0") {
                              newValue = "";  // Clear the value if the user is trying to type '0' as the first character
                              return;
                            }

                            if (newValue.length < 11) {
                              if (!text_type(newValue)) {
                                setEmail(newValue);
                              }
                            } else {
                              seterror("phone")
                            }
                          }} />
                      </div>
                    </div>
                  </div>

                  <div className={error == "phone" ? "form-group col-12 has-error" : "form-group col-12"} >
                    <div class="col-auto">
                      <label className="font-size-12">Password</label>
                      <div class="input-group border border-dark rounded">
                        <input type={show ? "text" : "password"} placeholder="Password" aria-describedby="inputGroupPrepend" className="form-control custom-placeholder" onChange={(v) => {
                          setPass(v.target.value);
                        }} />
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <label>Phone </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      placeholder="Phone"
                      onChange={(v) => {
                        if(v.target.value.length<11)
                        {
                          setEmail(v.target.value);
                        
                        }else {
                          alert("phone number invalid")
                        }
                        
                      }}
                    />
                  </div> */}



                  <div className={"form-group col-12 has-error"} >
                    <div class="col-auto">
                      <label
                        onClick={() => {
                          setShow(!show);
                        }}
                      >
                        {show ? (
                          <>
                            {" "}
                            <input type="checkbox" /><span checked style={{ color: '#1B83B2', fontSize: '13px' }} > Show Password</span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <input type="checkbox" unchecked style={{ color: '#1B83B2' }} /> <span style={{ color: '#1B83B2', fontSize: '13px' }}> Show Password</span>
                          </>
                        )}
                      </label>
                      <label className="pull-right">
                        <a href="/forgotten"
                          style={{
                            // bottom: '90px',
                            // zIndex: 1200,
                            // right: '30px',
                            color: 'black',
                            // padding: '10px 20px',
                            fontSize: '13px',
                            // borderRadius: '5px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontWeight: 600
                            // transition: 'all 0.3s ease',
                            // boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
                          }}
                        >Forgot Your Password?</a>
                      </label>
                    </div>
                  </div>
                  <div className={"form-group col-12 has-error"} >
                    <div class="col-auto">

                      <button
                        onClick={(e) => {


                          if (email.length == 10) {


                            if (token.length == "") {
                              loginNow(e);
                              console.log("abc")
                            } else {
                              loginNow(e);
                              console.log("abc done")
                            }
                          } else {
                            alert("Phone Number Invalid!")

                          }
                        }}
                        className="btn btn-flat m-b-30 m-t-30 mx-auto"
                        style={{
                          // bottom: '90px',
                          // zIndex: 1200,
                          // right: '30px',
                          borderRadius: '30px',
                          backgroundColor: '#2C99A1',
                          border: '2px solid #2C99A1',
                          color: 'white',
                          // padding: '10px 20px',
                          // fontSize: '16px',
                          // borderRadius: '5px',
                          cursor: 'pointer',
                          outline: 'none',
                          boxShadow: 'rgb(204, 204, 204) 0px 0px 17px 6px',
                          fontSize: '16px',
                          fontWeight: 500
                          // transition: 'all 0.3s ease',
                          // boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
                        }}
                        disabled={isLoading} // Disables the button when loading
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {" "}Please Wait...
                          </>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="register-link m-t-15 text-center">
                    <p>
                      Don't have account ? <a href="signup" style={{ color: '#45ACB3' }}> Sign Up</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
