import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import LogoSideNav from '../images/logo.png'

function Nav() {
  const [lead, setLead] = useState(false);
  const [bkhata, setBkhata] = useState(false);
  const [mediaBreakPoint, setMediaBreakPoint] = useState(false);
  const [darw, setDarw] = useState(false);
  
  // const [bwallet, setBwallet] = useState(false);

  const [bwarehouse, setBwarehouse] = useState(false);

  // const [blogistics, setBlogistics] = useState(false);
  // const [bnewbusiness, setBnewbusiness] = useState(false);
  // const [bad, setBad] = useState(false);
  // const [boffice, setBoffice] = useState(false);
  // const [binventory, setBinventory] = useState(false);
  const [userLogin, setUser] = useState("");
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  // const item = queryParams.get('item'); // Access the 'item' query parameter


  // ==================================================
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    setUser(v);
    if (v === null) {
      navigate("/login");

    }
  }, []);

  // useEffect hook to check the window's width on component mount and resize
  useEffect(() => {
    // Function to check the window's width
    const handleResize = () => {
      if (window.innerWidth <= 935) {
        setDarw(false);
        setMediaBreakPoint(true); // Set to true if the width is less than or equal to 935px
      } else {
        setDarw(true);// Set to false if the width is greater than 935px
        setMediaBreakPoint(false);
      }
    };

    // Call handleResize on initial render
    handleResize();

    // Add event listener to window resize event
    window.addEventListener('resize', handleResize);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //   const v = JSON.parse(localStorage.getItem("USER"));
  //   if (v === null) {
  //     navigate("/login");
  //   }
  // }, []);

  // const loginNow = async (e) => {
  //   const data = new FormData();
  //   data.append("key", e);

  //   data.append("status", "key");

  //   axios({
  //     method: "post",
  //     url: "/pro/login.php",
  //     data: data,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then((s) => {
  //       // console.log(s.data);
  //       localStorage.setItem("USER", JSON.stringify(s.data));
  //       setUser(s.data);

  //       if (s.data[0].msg === "pass") {
  //         localStorage.setItem("USER", JSON.stringify(s.data));
  //         navigate("/");
  //       } else if (s.data[0].msg === "block") {
  //         alert("Sorry! Your account is Disabled. Please, contact Admin");
  //       } else {
  //         alert("Invalid Email or Password! Please try again.");
  //       }
  //     })
  //     .catch((s) => {
  //       // console.log("here catch ", s);
  //     });
  // };
  return (
    <>
      {/* className="main-container" */}
      {darw ? (
        <>
          {console.log('get_params', location.pathname)}
          {" "}
          <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures" style={{ height: '100vh', overflow: 'hidden' }}>
            <div className="nano" style={{ height: '100%' }}>
              <div className="nano-content">


                <div className="logo mt-5">
                  <span>
                    <img
                      onClick={() => {
                        navigate("/");
                      }}
                      width="150px"
                      src={LogoSideNav}
                      alt=""
                    />
                  </span>
                </div>
                <SimpleBar
                    style={{
                      height: "calc(100vh - 100px)", // Correctly subtract space for the header/logo
                      overflowY: "auto",
                      padding: "0 5px",
                      marginTop: 20,
                    }}
                  >                  
                  <ul>
                    <li
                      onClick={() => {
                        navigate("/");
                      }}
                      className=""
                    >
                      {/* <span className="sidebar-sub-toggle" style={ location.pathname === '/buyer' && { backgroundColor: 'rgba(69, 174, 180, 0.8)' }} > */}
                      <span className="sidebar-sub-toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 960" width="22px" fill="#fff"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z" /></svg>

                        <span className="font-size-14">Main Website</span>{" "}
                      </span>
                      {/* </span> */}
                    </li>
                    <li
                      onClick={() => {
                        navigate("/buyer");
                      }}
                      className=""
                    >
                      <span className="sidebar-sub-toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 960" width="22px" fill="#fff"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>
                        <span className="font-size-14">Dashboard</span>
                      </span>
                    </li>

                    <li
                      onClick={() => {
                        setLead(!lead);
                      }}
                    >
                      <Link

                        className="sidebar-sub-toggle">
                        <div className="sidebar-items-content-container">
                          <div className="sidebar-sub-toggle-parent">

                            <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 960" width="22px" fill="#FFf"><path d="M160-200h160v-320H160v320Zm240 0h160v-560H400v560Zm240 0h160v-240H640v240ZM80-120v-480h240v-240h320v320h240v400H80Z" /></svg>
                            <span className="font-size-14 color-white">Manage Leads</span>{" "}
                          </div>
                          <div className="sub-container-sidebar-items">
                            <span style={{ color: 'white' }} className="sidebar-collapse-icon ti-angle-down"></span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="sidebar-sub">
                      {lead ? (
                        <>
                          <Link to="/product-buy-request">
                            <li className="sidebar-sub-toggle">

                              <i className="ti-wallet"></i>
                              <span className="font-size-14">Buying Requests</span>
                            </li>
                          </Link>

                        </>
                      ) : (
                        <></>
                      )}
                    </li>
                    <li>
                      {bwarehouse ? (
                        <>
                          <Link to="/requestedbooking">
                            <li
                              className="sidebar-sub-toggle"
                            >
                              <i className="ti-wallet"></i>Requested Bookings
                            </li>
                          </Link>

                          <Link to="/bookinginvoices">
                            <li
                              style={{
                                marginLeft: "23px",
                                marginBottom: "6px",
                                color: "white",
                              }}
                            >
                              <i className="ti-info-alt"></i> Booked Warehouses
                            </li>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </li>

                    <li
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                      onClick={() => {
                        setBkhata(!bkhata);
                      }}
                    >
                      <div
                        className="sidebar-sub-toggle"
                      >
                        <div className="sidebar-items-content-container">
                          <div className="sidebar-sub-toggle-parent">
                            <i className="ti-layout-grid4-alt" style={{ color: 'white' }}></i> <span className="font-size-14" style={{ color: 'white' }}>Khata</span>{" "}

                          </div>
                          <div className="sub-container-sidebar-items">
                            <span style={{ color: 'white' }} className="sidebar-collapse-icon ti-angle-down sub-container-sidebar-items"></span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      {bkhata ? (
                        <>
                        <Link to="/khata-dashboard">
                               <li className="sidebar-sub-toggle sidebar-sub">
                                  <i className="ti-home color-white"></i>
                                  <span className="font-size-14">Dashboard</span>
                                </li>
                              </Link>
                              <Link to="/khata-ledger">
                               <li className="sidebar-sub-toggle sidebar-sub">
                                  <i className="ti-list color-white"></i>
                                  <span className="font-size-14">Ledger / Invoice</span>
                                </li>
                              </Link>
                          <Link to="/buyerclient">
                            <li
                              className="sidebar-sub-toggle sidebar-sub"
                            >
                              <i className="ti-wallet color-white"></i><span className="font-size-14 color-white">Manage Clients</span>
                            </li>
                          </Link>


                          <Link to="/buyer_invoice">
                            <li
                              className="sidebar-sub-toggle sidebar-sub"
                            >
                              <i className="ti-wallet"></i><span className="font-size-14 color-white">Manage Invoices</span>
                            </li>
                          </Link>

                          <Link to="/purchaseorder">
                            <li
                              className="sidebar-sub-toggle sidebar-sub"
                            >
                              <i className="ti-info-alt"></i><span className="font-size-14 color-white">Purchase Order</span>
                            </li>
                          </Link>



                          <Link to="/buyerchallan">
                            <li
                              className="sidebar-sub-toggle sidebar-sub"
                            >
                              <i className="ti-wallet"></i><span className="font-size-14 color-white">Delivery Challan</span>
                            </li>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </li>


                  </ul>

                  <div
                    className="sidebar-logout-div"
                    onClick={() => {
                      localStorage.removeItem("USER");
                      navigate("/login");
                    }}
                  >
                    <span style={{ fontSize: '18px', color: '#fff', fontWeight: 400 }}>Sign Out</span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="rgba(69, 174, 180, 0.8)"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" /></svg>
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div
        style={{ padding: '1rem 3rem' }}
        className={darw ? ["header-protected-screen", "mobile-table"].join(' ') : ["header-protected-screen-full-width", "mobile-table"].join(' ')}>
        <div
          onClick={() => {
            setDarw(!darw);
          }}
          style={{
            border: '1px solid rgb(79, 151, 161)',
            height: '40px',
            borderRadius: '50%',
            width: '37px',
            background: 'rgb(79, 151, 161)',
            zIndex: 999
          }}
        >
          <div
            className="hamburger"
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        {/* Hamburger */}
        {/* <div style={{display: 'flex',flex:1}}>

        </div> */}

        {/* className="float-right" */}
        <div className="protected-header">
          <div className="header-icon">

            <Link to="/seller">
              <p
                style={{
                  // marginLeft: "83px",
                  paddingLeft: "11px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingRight: "11px",
                  backgroundColor: "#4F97A1",
                  border: "1px solid #4F97A1",
                  borderRadius: "20px",

                  color: "white",
                }}
                className="switch-seller-btn"
              >
                Switch to Seller
              </p>
            </Link>
          </div>
          {/* <div className="dropdown dib"></div> */}

          {/* <a href="/chat" > */}
          <Link to='/chat'>
            <div className="header-icon">

              <i style={{ color: "#4F97A1", fontWeight: 800 }} className="ti-email"></i>
            </div>
          </Link>
          {/* </a> */}


          <div className="user-avatar">
            <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 -960 960 960" fill="#4F97A1"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" /></svg>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 -960 960 960" fill="#d1d5db"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg> */}
            <span className="user-avatar-name">{userLogin.length > 0 ? <>{userLogin[0].name}</> : <></>}</span>

          </div>


          {/* <div className="dropdown dib">
                  <div className="header-icon" data-toggle="dropdown">
                    <span className="user-avatar text-uppercase"></span>
                    <div className="drop-down dropdown-profile dropdown-menu dropdown-menu-right">
                      <div>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="ti-user"></i>
                              <span>Personal Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="#">
                              <i className="ti-email"></i>
                              <span>Business Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="#">
                              <i className="ti-settings"></i>
                              <span>Director Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="/login">
                              <i className="ti-lock"></i>
                              <span>Certificate & Awards</span>
                            </a>
                          </li>
                          <li
                            onClick={() => {
                              localStorage.removeItem("USER");
                              navigate("/login");
                            }}
                          >
                            <a href="#">
                              <i className="ti-power-off"></i>
                              <span>Logout</span>
                            </a>
                          </li>

                          <li
                            onClick={() => {
                              alert("hi");
                              localStorage.removeItem("USER");
                              navigate("/login");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <a href="/login">
                              <i className="ti-close"></i> Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
          {/* </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Nav;
