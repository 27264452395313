import Nav from "./navbar";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function LedgerInvoicePage() {
  const [darw, setDarw] = useState(true);
  const [uid, setUid] = useState('');
  const [totals, setTotals] = useState({});
  const [ledgerHistory, setLedgerHistory] = useState([]);
  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTextLedger, setSearchTextLedger] = useState('');
  const [searchTextInvoice, setSearchTextInvoice] = useState('');
  const [isLedgerSearching, setIsLedgerSearching] = useState(false);
  const [isInvoiceSearching, setIsInvoiceSearching] = useState(false);

  const defaultStartDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  const defaultEndDate = new Date();

  const [ledgerStartDate, setLedgerStartDate] = useState(defaultStartDate);
  const [ledgerEndDate, setLedgerEndDate] = useState(defaultEndDate);
  const [invoiceStartDate, setInvoiceStartDate] = useState(defaultStartDate);
  const [invoiceEndDate, setInvoiceEndDate] = useState(defaultEndDate);

  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [downloading, setDownloading] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('USER'));
    if (user && user[0]?.id) {
      setUid(user[0].id);
      fetchData(user[0].id);
    } else {
      setError('User not logged in.');
    }
  }, []);

  const fetchData = (uid) => {
    const data = new FormData();
    data.append('api_type', 'all');
    data.append('uid', uid);

    axios({
      method: "post",
      url: "/pro/khata/LedgerReportByUID.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (s.data.code === 'success') {
          setTotals({
            highest_debit: parseFloat(s.data.totals.highest_debit).toFixed(2),
            total_debit: parseFloat(s.data.totals.total_debit).toFixed(2),
            highest_credit: parseFloat(s.data.totals.highest_credit).toFixed(2),
            total_credit: parseFloat(s.data.totals.total_credit).toFixed(2),
            total_balance: parseFloat(s.data.totals.total_balance).toFixed(2),
          });
          setLedgerHistory(s.data.ledger_history);
          setInvoiceHistory(s.data.invoice_history);
        } else {
          setError(s.data.message);
        }
      })
      .catch((s) => {
        setError('Failed to fetch data.');
        console.log("here catch =>>", s);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFilteredData = (apiType, startDate, endDate, setData, setLoadingState) => {
    const data = new FormData();
    data.append('api_type', apiType);
    data.append('uid', uid);
    if (startDate) data.append('start_date', startDate.toISOString().split('T')[0]);
    if (endDate) data.append('end_date', endDate.toISOString().split('T')[0]);

    setLoadingState(true);
    axios({
      method: "post",
      url: "/pro/khata/LedgerReportByUID.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (s.data.code === 'success') {
          if (apiType === 'ledger') setData(s.data.ledger_history);
          if (apiType === 'invoice') setData(s.data.invoice_history);
        } else {
          setError(s.data.message);
        }
      })
      .catch((s) => {
        setError('Failed to fetch data.');
        console.log("here catch =>>", s);
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter(row =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const downloadInvoice = async (id, invoiceId, clientName) => {
      try {
          setButtonsDisabled(true);
          setDownloading({ [id]: true });

          const iframe = document.createElement('iframe');
          iframe.style.position = 'absolute';
          iframe.style.top = '-9999px';
          iframe.style.width = '1024px'; // Set iframe width to match browser window
          iframe.style.height = '768px'; // Set iframe height to match browser window
          iframe.src = `/invoiceprint?id=${id}`;
          document.body.appendChild(iframe);

          iframe.onload = async () => {
              try {
                  const iframeDocument = iframe.contentWindow.document;

                  // Wait for the content to fully load (polling approach)
                  const waitForContent = async () => {
                      return new Promise((resolve) => {
                          const interval = setInterval(() => {
                              if (iframeDocument.querySelector('#invoice-loaded-marker')) { // Ensure the marker exists
                                  clearInterval(interval);
                                  resolve();
                              }
                          }, 100);
                      });
                  };

                  await waitForContent(); // Wait for the marker indicating the content is loaded

                  // Use html2canvas to capture the full iframe content as an image
                  const canvas = await html2canvas(iframeDocument.body, {
                      scale: 2, // Higher scale for better resolution
                      windowWidth: iframeDocument.body.scrollWidth,
                      windowHeight: iframeDocument.body.scrollHeight,
                      y: 20 // Remove the top 20px when capturing
                  });

                  const imageData = canvas.toDataURL('image/png');

                  // Create a link to download the image
                  const link = document.createElement('a');
                  link.href = imageData;
                  link.download = `Invoice_${id}_${invoiceId}_${clientName}.png`;
                  link.click();

                  // Remove the iframe after processing
                  document.body.removeChild(iframe);
              } catch (renderError) {
                  console.error('Failed to capture the full invoice as an image:', renderError);
              } finally {
                  setButtonsDisabled(false);
                  setDownloading({ [id]: false });
              }
          };
      } catch (error) {
          console.error('Failed to download the invoice as an image:', error);
      }
  };

  const columnsLedger = [
    { name: 'Transaction ID', selector: row => row.transaction_id, sortable: true },
    { name: 'Client Name', selector: row => row.client_name, sortable: true },
    { name: 'Date', selector: row => row.time, sortable: true },
    {
      name: 'Debit',
      selector: row => parseFloat(row.debit).toFixed(2),
      sortable: true,
    },
    {
      name: 'Credit',
      selector: row => parseFloat(row.credit).toFixed(2),
      sortable: true,
    },
    {
      name: 'Balance',
      selector: row => parseFloat(row.balance).toFixed(2),
      sortable: true,
    },
  ];

  const columnsInvoice = [
    { name: 'Invoice ID', selector: row => row.invoice_id, sortable: true },
    { name: 'Client Name', selector: row => row.client_name, sortable: true },
    { name: 'Invoice Date', selector: row => row.invoice_date, sortable: true },
    {
      name: 'Total Amount',
      selector: row => parseFloat(row.total).toFixed(2),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="d-flex">
          <button
            className="btn btn-primary me-2"
            onClick={() => window.open(`/invoiceprint?id=${row.id}`, '_blank')}
          >
            View
          </button>
          <button
            className="btn btn-info"
            onClick={() => downloadInvoice(row.id, row.invoice_id, row.client_name)}
            disabled={downloading[row.id] || buttonsDisabled}
          >
            {downloading[row.id] ? (
                <>
                Download <span className="spinner-border spinner-border-sm"></span>
                </>
            ) : (
                'Download'
            )}
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px' 
    },
  ];

  // if (loading) return <div className="text-center mt-5">Loading...</div>;
  // if (error) return <div className="alert alert-danger mt-5">{error}</div>;

  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', padding: '0px' }}
        className={darw
          ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ')
          : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ')
        }
      >
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-md-4">
              <div className="card mb-3" style={{ border: "1px solid #d9534f" }}>
                <div className="card-body d-flex justify-content-between">
                  <div>
                    <h6 style={{ color: "#d9534f" }}>Highest Debit</h6>
                    <h5>{totals.highest_debit}/-</h5>
                  </div>
                  <div>
                    <h6 style={{ color: "#5cb85c" }}>Total Debit</h6>
                    <h5>{totals.total_debit}/-</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3" style={{ border: "1px solid #d9534f" }}>
                <div className="card-body d-flex justify-content-between">
                  <div>
                    <h6 style={{ color: "#d9534f" }}>Highest Credit</h6>
                    <h5>{totals.highest_credit}/-</h5>
                  </div>
                  <div>
                    <h6 style={{ color: "#5cb85c" }}>Total Credit</h6>
                    <h5>{totals.total_credit}/-</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3" style={{ backgroundColor: "#5bc0de", color: "#fff" }}>
                <div className="card-body text-center">
                  <h6>Total Balance</h6>
                  <h5>{totals.total_balance}/-</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h4>Ledger History</h4>
              <div className="d-flex mb-3 justify-content-between">
                <div className="d-flex">
                  <DatePicker
                    selected={ledgerStartDate}
                    onChange={(date) => setLedgerStartDate(date)}
                    placeholderText="Start Date"
                    className="form-control me-2"
                  />
                  <DatePicker
                    selected={ledgerEndDate}
                    onChange={(date) => setLedgerEndDate(date)}
                    placeholderText="End Date"
                    className="form-control me-2"
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => fetchFilteredData('ledger', ledgerStartDate, ledgerEndDate, setLedgerHistory, setIsLedgerSearching)}
                    disabled={isLedgerSearching}
                  >
                    {isLedgerSearching ? <span className="spinner-border spinner-border-sm"></span> : "Search"}
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control w-25"
                  placeholder="Search Ledger..."
                  value={searchTextLedger}
                  onChange={(e) => setSearchTextLedger(e.target.value)}
                />
              </div>
              <DataTable
                columns={columnsLedger}
                data={handleSearch(ledgerHistory, searchTextLedger)}
                pagination
                highlightOnHover
                responsive
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <h4>Invoice History</h4>
              <div className="d-flex mb-3 justify-content-between">
                <div className="d-flex">
                  <DatePicker
                    selected={invoiceStartDate}
                    onChange={(date) => setInvoiceStartDate(date)}
                    placeholderText="Start Date"
                    className="form-control me-2"
                  />
                  <DatePicker
                    selected={invoiceEndDate}
                    onChange={(date) => setInvoiceEndDate(date)}
                    placeholderText="End Date"
                    className="form-control me-2"
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => fetchFilteredData('invoice', invoiceStartDate, invoiceEndDate, setInvoiceHistory, setIsInvoiceSearching)}
                    disabled={isInvoiceSearching}
                  >
                    {isInvoiceSearching ? <span className="spinner-border spinner-border-sm"></span> : "Search"}
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control w-25"
                  placeholder="Search Invoice..."
                  value={searchTextInvoice}
                  onChange={(e) => setSearchTextInvoice(e.target.value)}
                />
              </div>
              <DataTable
                columns={columnsInvoice}
                data={handleSearch(invoiceHistory, searchTextInvoice)}
                pagination
                highlightOnHover
                responsive
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LedgerInvoicePage;
