import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginImage from '../images/login.png';

function Login() {
  const navigate = useNavigate();
  const [passShow, setpassShow] = useState(false);
  const [passShow2, setpassShow2] = useState(false);
  const [error, seterror] = useState("");
  const [name, setName] = useState("");
  const [cname, setCname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pass, setPass] = useState("");
  const [pas2, setPass2] = useState("");
  const [cityName, setCityName] = useState("Karachi");
  const [cityIndex, setCityIndex] = useState();
  const [province, setProvince] = useState();
  const [listcities, setCitiesList] = useState([]);

  const [main, setMainCat] = useState([]);
  const [cat, setCat] = useState("");

  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://' + window.location.hostname;

  useEffect(() => {
    getCities()
    getMainCat()

  }, []);

  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {

      return false;
    }
  }
  const getCities = async () => {
    const data = new FormData();

    axios({
      method: "post",
      url: "/pro/auth/cities_list.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setCitiesList(s.data)
      })
      .catch((s) => {

      });


  };
  const text_type = (input) => {
    // Regular expression to check if the string contains only alphabetic characters
    const regex = /^[A-Za-z\s]+$/;

    // Test the input against the regular expression
    return regex.test(input);

  }

  const SigupReady = () => {
    setIsLoading(true); // Start loading

    const data = new FormData();
    data.append("name", name);
    data.append("cname", cname);
    data.append("email", email);
    data.append("phone", phone);
    data.append("city", cityName);
    data.append("address", address);
    data.append("pass", pass);
    data.append("province", province);
    data.append("img", "");
    data.append("cnic", pass);
    data.append("cat", cat);

    axios({
      method: "post",
      url: "/pro/auth/reg.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setIsLoading(false); // Stop loading
        console.log("signup response", s.data);
        if (s.data[0].code === "pass") {
          alert("You have been successfully registered.");
          localStorage.setItem("signupDetails", JSON.stringify({ phone, pass }));
          navigate("/otp");
        } else if (s.data[0].code === "already") {
          alert("Account is already existed.");
        } else {
          alert("Something went wrong. Try again.");
        }
      })
      .catch((err) => {
        setIsLoading(false); // Stop loading on error
        console.error(err);
      });
  };

  const SignUpNow = async () => {





    if (name == "") {
      seterror("name")
      alert("Enter Name");
    }
    else if (cname == "") {
      seterror("cname")
      alert("Enter Company Name");
    }

    else if (phone == "") {
      seterror("phone")
      alert("Enter Phone");
    }
    else if (phone.length < 9) {
      seterror("phone")
      alert("Enter Right Phone no.")
    }

    else if (pass != pas2) {
      seterror("pass")
      alert("Password Not Match !")
    }

    else if (pass.length < 8) {
      seterror("pass")
      alert("Password Length must be greater than 8 Digit!")
    }

    else {

      if (email == "") {
        SigupReady()

      } else {
        if (ValidateEmail(email)) {
          SigupReady()
        } else {
          alert("Enter valid Email")
          seterror("email")
        }
      }




    }

  };

  return (
    <div className="unix-login">
      <div className="container-fluid" style={{padding: '0px'}}>
        <div className="row">
          {/* // <body
        //   style={{
        //     backgroundImage: `url("https://alahdeen.com/pro/image/test.jpg")`,
        //     minHeight: "100vh",
        //     backgroundImage: `url("https://alahdeen.com/pro/image/test.jpg")`,
        //     backgroundSize: "cover",
        //     backgroundRepeat: "no-repeat",
        //     backgroundAttachment: "fixed",
        //     backgroundPosition: "center", 
        //   }}
        // > */}


          <div className="col-6 d-none d-lg-block"
            style={{

              // maxHeight: '100vh',
              // position: 'sticky'
            }}
          >
            <img src={LoginImage}
              style={{
                width: '100%', /* Ensure the image takes full width of the container */
                height: '100%', /* Ensure the image takes full height */
                objectFit: 'cover'
              }}
            //  className="w-100 h-100"
            />
          </div>

          <div className="col-12 col-lg-6">
            <div className="login-content px-3 px-md-0">
              {/* <div className="login-logo">
              </div> */}
              <div style={{ opacity: 0.9 }} className="login-form">
                <h4 style={{ fontSize: '22px', color: '#1D2630', marginTop: "30px", letterSpacing: '1.4px' }} className="font-bold">Register Now  </h4>
                {/* <h4 className="text-uppercase">
                </h4> */}
                {/* <center
                  style={{
                    marginBottom: '50px'
                  }}
                >
                  <img

                    onClick={() => {
                      navigate("/");
                    }}
                    style={{
                      // marginTop: "20px",
                      cursor: "pointer",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    src="assets/images/logob.png"
                    height={90}
                  />
                </center> */}


                <div className="row">
                  <div className={error == "name" ? "form-group col-md-6 has-error" : "form-group col-md-6"}  >
                    <label> Enter Full Name *</label>
                    <input
                      // style={{border: '1px solid #EFEFEF'}}
                      type="text"
                      className="form-control border-dark border rounded"
                      placeholder="Enter Full Name"
                      maxLength={30}
                      value={name}
                      onChange={(v) => {

                        if (text_type(v.target.value)) {
                          setName(v.target.value);
                        } else if (v.target.value == "") {
                          setName(v.target.value);
                        }

                      }}
                    />
                  </div>
                  <div className={error == "cname" ? "form-group col-md-6 has-error" : "form-group col-md-6"} >
                    <label>Company Name *</label>
                    <input
                      type="text"
                      className="form-control border border-dark rounded"
                      placeholder="Company Name"
                      value={cname}
                      maxLength={30}
                      onChange={(v) => {

                        if (text_type(v.target.value)) {
                          setCname(v.target.value);
                        } else if (v.target.value == "") {
                          setCname(v.target.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className={error == "phone" ? "form-group col-md-6 has-error" : "form-group col-md-6"} >
                    <div class="">
                      <label>Phone No. *</label>
                      <div class="input-group border border-dark rounded">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="inputGroupPrepend">+92</span>
                        </div>
                        <input type="text" placeholder="Phone No." aria-describedby="inputGroupPrepend" className="form-control" value={phone} onChange={(v) => {
                          let newValue = v.target.value;
                          newValue = newValue.replace(/[.-]/g, "");

                          // Update the state with the valid value
                          // setPhone(newValue);

                          if (newValue.charAt(0) === "0") {
                            newValue = "";  // Clear the value if the user is trying to type '0' as the first character
                            return;
                          }
                          if (newValue.length <= 10) {
                            if (!text_type(newValue)) {
                              setPhone(newValue);
                            }
                          } else {
                            alert("Enter valid Phone Number")
                          }

                          // setPhone(newValue);
                          // if (v.target.value.length <= 10) {
                          //   if (!text_type(v.target.value)) {
                          //     setPhone(v.target.value);
                          //   }

                          //   if (checkValue[0] == '0') {
                          //     console.log('check if', newValue);
                          //     setPhone(newValue);
                          //   }
                          // } else {
                          //   alert("Enter valid Phone Number")
                          // }

                        }} />
                      </div>
                    </div>
                  </div>

                  <div className={error == "email" ? "form-group col-md-6 has-error" : "form-group col-md-6"} >
                    <label>Email (optional) </label>
                    <input
                      type="email"
                      className="form-control border border-dark rounded"
                      placeholder="Email"

                      onChange={(v) => {
                        setEmail(v.target.value);
                      }}
                    />
                  </div>

                </div>
                {/* <div className="form-group">
                    <label>CNIC No.</label>
                    <input
                    type="text"
                    className="form-control"
                      placeholder="Enter CNIC No."
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      value={cnic}
                      onChange={(v) => {
                        const a = v.target.value;

                        if (a.length === 5) {
                          setCnic(a + "-");
                        } else if (a.length === 13) {
                          setCnic(a + "-");
                        } else if (a.length == 16) {
                        } else {
                          setCnic(v.target.value);
                        }
                      }}
                    />
                  </div> */}

                {/* Adress Start */}
                {/* <div className="form-group">
                    <label>Address</label>
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Address"
                      onChange={(v) => {
                        setAddress(v.target.value);
                      }}
                    />
                  </div> */}

                {/* <div className="row">
                  <div  className= {error =="city"?"form-group col-6 has-error":"form-group col-6"} >
                    <label>City</label>
                    <select
                      value={cityIndex}
                      onChange={(v) => {

                          setCityIndex(v.target.value);
                          setCityName(listcities[v.target.value].city)
                          setProvince(listcities[v.target.value].admin_name)

                      }}
                      className="form-control"
                    >
                  {
                  listcities.map((v,i)=>(
<>
<option value={i} >{v.city}</option>
</>
                  ))
                  }
                    </select>
                  </div>


                    <div  className= {error =="add"?"form-group col-6 has-error":"form-group col-6"} >
                      <label>Address (optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Address"
                        value={address}
                        onChange={(v) => {
                          setAddress(v.target.value);
                        }}
                      />
                    </div>
                    </div> */}


                {/* <div className="row" >
                              <div className="col-12 form-group">
                              <label>Select Main Category</label>
                                <select
                                style={{ borderColor: error==false?"black":cat==""?"red":"black" }}
                                  value={cat}
                                  onChange={ve => {
                                    setCat(ve.target.value)
                                  }}
                                  className="form-control">
                                  <option>Select Main Category</option>

                                  {main.map((v, i) => (
                                    <option value={v.id}>{v.title}</option>
                                  ))}
                                </select>
                              </div>
                            </div> */}



                <div className="row">
                  <label>Password *</label>
                  <div className={error == "pass" ? "form-group col-12 has-error" : "form-group col-12"}>
                    <div className="input-group mb-3 border border-dark rounded">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fas fa-lock"></i>
                        </span>
                      </div>

                      <input
                        name="password"
                        type={passShow ? "text" : "password"}
                        onChange={(v) => {
                          setPass(v.target.value);
                        }}
                        value={pass}
                        className="input form-control"
                        placeholder="password"
                      />
                      <div
                        onClick={() => {
                          setpassShow(!passShow);
                        }}
                        className="input-group-append"
                      >
                        <span className="input-group-text">
                          {passShow ? (
                            <>
                              <i className="fas fa-eye-slash" id="hide_eye"></i>
                            </>
                          ) : (
                            <i className="fas fa-eye" id="show_eye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <label>Confirm Password</label>
                  <div className={error == "pass" ? "form-group col-md-12 has-error" : "form-group col-md-12"}>
                    <div className="input-group mb-3 border border-dark rounded">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fas fa-lock"></i>
                        </span>
                      </div>

                      <input
                        name="Confirm Password"
                        type={passShow2 ? "text" : "password"}
                        onChange={(v) => {
                          setPass2(v.target.value);
                        }}
                        value={pas2}
                        className="input form-control"
                        placeholder="password"
                      />
                      <div
                        onClick={() => {
                          setpassShow2(!passShow2);
                        }}
                        className="input-group-append"
                      >
                        <span className="input-group-text">
                          {passShow2 ? (
                            <>
                              <i className="fas fa-eye-slash" id="hide_eye"></i>
                            </>
                          ) : (
                            <i className="fas fa-eye" id="show_eye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <label>Add Image</label>y
                    <br />
                    <input
                      className="form-control"
                      type="file"
                      onChange={(v) => {
                        setImg(v.target.files[0]);
                      }}
                    />
                  </div> */}

                  {/* <div className="checkbox">
                    <label>
                      <input type="checkbox" /> Agree the terms and policy
                    </label>
                  </div> */}
                  <div className={error == "pass" ? "form-group col-md-12 has-error" : "form-group col-md-12"}>

                    <button
                      type="submit"
                      onClick={() => {
                        SignUpNow();
                      }}
                      className="btn btn-flat m-b-30 m-t-30"
                      disabled={isLoading} // Disables the button when loading
                      style={{
                        color: 'whitesmoke',
                        borderRadius: '30px',
                        boxShadow: 'rgb(204, 204, 204) 0px 0px 17px 6px',
                        backgroundColor: '#2C99A1',
                        border: '1px solid #2C99A1',
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"

                          ></span>
                          {" "}Please Wait...
                        </>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  </div>
                  <div className={error == "pass" ? "form-group col-md-12 has-error" : "form-group col-md-12"}>
                    <div className="register-link m-t-15 text-center">
                      <Link to={"/login"}>
                        <span style={{ color: '#868e96' }}>Already have account? </span>
                        <span style={{ color: "#45ACB3" }}>
                          Sign in
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
