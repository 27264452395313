import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Nav from "../../../navbar";

function Mainpage() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [productList, setProductList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [alert, setAlert] = useState(false);
  const [alert3, setAlert3] = useState(false);
  const [prom, setProm] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [pid, setPid] = useState('');
  const [del, setDel] = useState('');
  const [darw, setDarw] = useState(true);

  // Update base URL
  axios.defaults.baseURL =
    window.location.hostname === "localhost"
      ? "http://localhost"
      : "https://" + window.location.hostname;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user) {
      setUid(user[0].id);
      viewProduct(user[0].id);
    }
  }, []);

  const viewProduct = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    try {
      const response = await axios.post("/pro/view_product_user.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const updateImageURLs = (url) =>
    url.replace("https://alahdeen.com", "https://stage.alahdeen.com");

  const columns = [
    {
      name: "#",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
      minWidth: "50px",
    },
    {
      name: "Image",
      selector: (row) => (
        <img
          src={updateImageURLs(row.img)}
          alt="product"
          style={{ width: "50px" }}
        />
      ),
      maxWidth: "75px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row.catname,
      sortable: true,
      hide: "md",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      hide: "md",
    },
    {
      name: "Quantity",
      selector: (row) => row.qty,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => deleteproduct(row.pid)}
          >
            Delete
          </button>
          <button
            className="btn btn-primary btn-sm ml-2"
            onClick={() => navigateToEdit(row.pid)}
          >
            Edit
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "200px",
    },
  ];

  const navigateToEdit = (pid) => {
    localStorage.setItem("ProductEditId", pid);
    navigate("/productedit");
  };

  const deleteproduct = async (pid) => {
    try {
      const data = new FormData();
      data.append("pid", pid);
      data.append("val", "delete");
      await axios.post("/pro/product_manage.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      viewProduct(uid); // Refresh product list
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const ExpandableRow = ({ data }) => (
    <div style={{ padding: '10px' }}>
      <p>
        <strong>Category:</strong> {data.catname || 'N/A'}
      </p>
      <p>
        <strong>Price:</strong> {data.price || 'N/A'}
      </p>
      <p>
        <strong>Quantity:</strong> {data.qty || 'N/A'}
      </p>
      <p>
        <strong>Status:</strong> {data.status || 'N/A'}
      </p>
    </div>
  );  
  
  const Runpromotion = (pid, price) => {
    setPid(pid)
    setProm(price)
    setAlert(true)


  };

  const Promotion = async () => {
    const data = new FormData();
    data.append('type', 'yes');
    data.append('uid', uid);
    data.append('pid', pid);
    data.append('price', prom);
    data.append('start', start);
    data.append('end', end);

    axios({
      method: 'post',
      url: '/pro/leadproductpromostion.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log('product list ', s.data);

        viewProduct(uid);

      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const RemPromotion = async (pid) => {
    const data = new FormData();
    data.append('type', 'no');
    data.append('uid', uid);
    data.append('pid', pid);


    axios({
      method: 'post',
      url: '/pro/leadproductpromostion.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log('product list ', s.data);

        viewProduct(uid);

      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{ overflow: "auto", display: "flex", flexDirection: "column", padding: "0px" }}
        className={
          darw
            ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
            : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(" ")
        }
      >
        <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={handleSearch(productList, searchText)}
          pagination
          responsive
          highlightOnHover
          expandableRows
          expandableRowsComponent={ExpandableRow}
        />
        {/* <div className="content-wrap">
          <div className="main">
            <div className="container-fluid">

              <section id="main-content">

                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-title">
                      <h4> Product List</h4>
                    </div>

                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover ">
                          <thead>
                            <tr>
                              <th>ID </th>
                              <th>Featured Image</th>
                              <th>Title</th>
                              <th>Product code</th>
                              <th>Price</th>
                              <th>Category</th>
                              <th>Promotion</th>
                              <th>Status</th>
                              <th>Controls</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productList.length === 0 ? (
                              <>
                                <tr>
                                  <td
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                    }}
                                    colSpan={10}>
                                    No Record to show{' '}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                {productList.map((v, i) => (
                                  <tr>
                                    <td>{v.counter}</td>
                                    <td style={{ width: '20%' }}>
                                      <img
                                        style={{ width: "120px", height: "120px" }} alt={v.title}
                                        src={v.img.length <= 1 ? "https://alahdeen.com/pro/product_img/image.jpg" : v.img}
                                      />
                                    </td>

                                    <td>{v.title}</td>
                                    <td>{v.code}</td>
                                    <td>{v.pro_type == 'yes' ? <><p style={{ color: "green" }}>{v.pro_price}  <del style={{ color: "red" }}>{v.price}</del></p></> : <>{v.price}</>}</td>
                                    <td>{v.catname}</td>
                                    <td style={{ color: "blue", cursor: "pointer" }}>

                                      {v.status == 'active' && v.pro_type == 'yes' ? <><p onClick={() => { RemPromotion(v.pid) }} style={{ color: "green" }}>Remove Promotion</p></> : v.status == 'inactive' ? <>Promotion Inactive</> : <p style={{ color: "blue" }} onClick={() => {
                                        Runpromotion(v.pid, v.price)
                                      }}> <u >  Apply for Promotion</u></p>}

                                    </td>
                                    <td>
                                      {v.pro_type == "no" && v.status == 'active' ? (
                                        <>
                                          <p
                                            style={{
                                              cursor: 'pointer',
                                              color: 'green',
                                            }}
                                            onClick={() => {
                                              productStatus(v.pid, 'inactive');
                                            }}>
                                            {v.status}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p
                                            style={{
                                              cursor: 'pointer',
                                              color: 'red',
                                            }}
                                            onClick={() => {
                                              productStatus(v.pid, 'active');
                                            }}>
                                            {v.status}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                    <td>




                                      <label
                                        className='mr-1'
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => {
                                          localStorage.setItem("ProductEditId", v.pid)
                                          navigate("/productedit")
                                        }}>
                                        Edit
                                      </label>
                                      |
                                      <label
                                        className='ml-1'
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => {
                                          setDel(v.pid)
                                          setAlert3(true)
                                        }}>
                                        Delete
                                      </label>
                                    </td>
                                  </tr>

                                ))}

                              </>

                            )}

                          </tbody>
                        </table>

                        {alert3 ? (
                          <>

                            <SweetAlert
                              warning
                              confirmBtnText="Delete"
                              confirmBtnBsStyle="success"
                              title="Confirm Delete"
                              allowEscape={false}
                              onConfirm={() => {
                                deleteproduct(del);

                                setAlert3(!alert3);
                              }}
                              onCancel={() => {
                                setAlert3(!alert3);
                              }}

                              focusCancelBtn
                            >
                              <br />
                            </SweetAlert>
                          </>
                        ) : (
                          <></>
                        )}
                        {alert ? (
                          <>
                            {' '}
                            <SweetAlert

                              confirmBtnText="Confirm"
                              cancelBtnText="Confirm"
                              confirmBtnBsStyle="success"
                              title="Add Promotion"

                              onConfirm={() => {
                                Promotion()
                                setAlert(!alert);

                              }}
                              onCancel={() => {
                                setAlert(!alert);
                              }}
                              focusCancelBtn>
                              <br />
                              <label >Promotion Price </label>
                              <input value={prom} onChange={(v) => { setProm(v.target.value) }} className='form-control' />
                              <br />
                              <div className='row'>
                                <div className='col-6'>
                                  <label>Start Date</label>
                                  <input value={start} onChange={(v) => { setStart(v.target.value) }} className='form-control' type='date' />
                                </div>
                                <br />

                                <div className='col-6'>
                                  <label>End Date</label>
                                  <input value={end} onChange={(v) => { setEnd(v.target.value) }} className='form-control' type='date' />
                                </div>
                              </div>
                              <br />
                            </SweetAlert>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        {/* --------------------------------- */}
      </div>
    </div>
    </div>
  );
}

export default Mainpage;
