import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState();
  const [cid, setCid] = useState();
  const [n, setN] = useState();
  const [error, setError] = useState("");



  const [uid, setUid] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    const cid = localStorage.getItem("cid");
    const cname = localStorage.getItem("cname");
    setN(cname)
    setCid(cid);
 
   
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);



  const addpayment = async (uid) => {
    const data = new FormData();
    data.append("cid", cid);
    data.append("val", "pay_invoice");
    data.append("am", amount);
    data.append("uid", uid);

    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data);
        navigate("/khataclient");
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const getallClient = async (uid) => {
    const data = new FormData();
    data.append("uid", uid);

    axios({
      method: "post",
      url: "/pro/khata/khata_view_clients.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // setAllClient(s.data);
        localStorage.setItem("KhataListClient", JSON.stringify(s.data));
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  return (
    <>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Add Payment</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5> </h5>
                        </div>

                        <div className="card-body">
                          <div className="basic-elements">
                            <div className="row">
                              <div className="col-6 form-group">
                                <label> Name</label>

                                <input
                                  value={n}
                                  className="form-control"
                                  type="text"
                                  disabled
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Amount</label>
                                <input
                                  className="form-control"
                                  value={amount}
                                  onChange={(v) => {
                                    const value = v.target.value;
                                    if (/^-?\d*\.?\d{0,2}$/.test(value) || value === "") {
                                      setAmount(value);
                                      setError(""); // Clear error
                                    } else {
                                      setError("Please enter a valid amount (e.g., -123, 123, or 123.45)");
                                    }
                                  }}
                                  type="text"
                                  placeholder="Enter amount"
                                />
                                {error && <small style={{ color: "red" }}>{error}</small>}
                              </div>
                            </div>
                            <center>
                              <div
                                onClick={() => {
                                  addpayment(uid);
                                }}
                                className="btn-manage-client btn btn-primary"
                              >
                                Save
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
