import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import Header from './header';
import Footer from './footer';

import Whatsapp from '../components/landingpage/whatsapp'



function Cat() {
  const [allcats, setAllcats] = useState([]);

  useEffect(() => {
    getServercats();
  }, []);

  const getServercats = async () => {
    const data = new FormData();

    data.append("id", "0");
    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",

      url: "/mainsite/suball.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        console.log(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  return (
    <>
      <Header />
      <Whatsapp />
      <section className="section-box pt-50 bg-home9">
        <div className="container">

          {allcats.map((v, i) => (

            <>
              <div className="box-product-category">
                <div className='title-border-bottom mb-20'>
                  <div className='row'>
                    <div className='col'>
                      <h5>{v.title}</h5>
                    </div>
                    <div className='col text-right'>
                      <span className='text-right'>
                        <a
                          onClick={() => {
                            localStorage.setItem("title", v.title);
                          }}
                          href={"/sub/" + v.bread_crumbs}
                          class="btn text-light border rounded-pill px-3 py-1" style={{backgroundColor: 'rgb(69, 172, 179)', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'}}>View All</a></span>

                    </div>

                  </div>


                </div>

                <div className="d-flex">

                  <div className="box-category-right">
                    <div className="row">
                      {
                        allcats[i].child.map((v, i) => (
                          <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <div className="card-grid-style-3">
                              <div className="card-grid-inner">

                                <div className="image-box">

                                  <a href={"/sub/" + v.bread_crumbs} onClick={() => {
                                    localStorage.setItem("title", v.name);
                                  }}>
                                    <img
                                      src={v.img}
                                      alt={v.img}
                                    />
                                  </a>
                                </div>
                                <div className="info-right">

                                  <a
                                    className="color-brand-3 font-sm-bold"
                                    href={"/sub/" + v.bread_crumbs}
                                    onClick={() => {
                                      localStorage.setItem("title", v.title);
                                    }}
                                  >
                                    {v.title}
                                  </a>


                                </div>
                              </div>
                            </div>
                          </div>
                        ))}





                    </div>
                  </div>
                </div>
              </div>




            </>
          ))}






        </div>
      </section>
      <Footer />
    </>
  )
}

export default Cat