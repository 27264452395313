import { useState, useEffect } from 'react';
import axios from 'axios';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  PlusCircleFill,
  Trash,
  Display,
  CircleFill,
} from 'react-bootstrap-icons';
import Nav from '../../../navbar';
import DataTable from 'react-data-table-component';

function Mainpage(navigation) {
  const navigate = useNavigate();
  const [allclient, setAllClient] = useState([]);
  const [uid, setUid] = useState('');
  const [darw, setDarw] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getallClient(v[0].id);
    }
  }, []);

  const deletekahat = async (pid) => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('pid', pid);
    data.append('val', 'delete');

    axios({
      method: 'post',
      url: '/pro/khata/khata_view_clients.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((s) => {
        console.log('status list ', s.data.code);
        getallClient(uid);
      })
      .catch((s) => {
        console.log('here catch =>>', s);
      });
  };

  // useEffect(() => {
  //   const v = JSON.parse(localStorage.getItem('USER'));
  //   if (v === null) {

  //   } else {
  //     setUid(v[0].id)
  //   }
  // }, []);

  const getallClient = async (uid) => {
    const data = new FormData();
    
    data.append("uid", uid);
    data.append("val", "view_client");
    
    
    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllClient(s.data);
        console.log("history",s.data);
       
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter(row =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const columns = [
    { name: 'ID', selector: (_, index) => index + 1, sortable: true },
    { name: 'Name', selector: row => JSON.parse(row.array)[0]?.name || '', sortable: true },
    { name: 'Email', selector: row => JSON.parse(row.array)[0]?.email || '', sortable: true },
    { name: 'Phone', selector: row => JSON.parse(row.array)[0]?.phone || '', sortable: true },
    { name: 'Address', selector: row => JSON.parse(row.array)[0]?.add || '', sortable: true },
    { name: 'Client Balance', selector: row => row.balance, sortable: true },
    {
      name: 'Settle Up',
      cell: row => (
        <button
          className={row.balance > 0 ? 'btn-manage-client btn btn-sm btn-success' : 'btn-manage-client btn btn-sm btn-primary'}
          onClick={() => {
            localStorage.setItem('cid', row.cid);
            localStorage.setItem('cname', JSON.parse(row.array)[0]?.name || '');
            navigate('/addbalance');
          }}
        >
          {row.balance > 0 ? 'Pay Amount' : 'Settle Balance'}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px' 
    },
  ];

  return (
    <body>
      <Nav setDarw={setDarw} darw={darw} />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Manage Clients</h4>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 text-right">
                      <Link to="/clientAddform">
                        <button
                          className="btn btn-success"
                        >
                          Add Client
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <DataTable
                      columns={columns}
                      data={handleSearch(allclient, searchText)}
                      pagination
                      highlightOnHover
                      responsive
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
