import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SendWelcome, generateToken } from "./utils"; // Import the SendWelcome, handleClick function from the shared utility file
import '../styles/auth.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Login() {



  const navigate = useNavigate();
  const [error, seterror] = useState("");
  const [name, setName] = useState("");
  // const [otp, setOTP] = useState("");
  const [token, setToken] = useState('');
  const [uid, setUid] = useState('');
  axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost' : 'https://' + window.location.hostname;
  const [isLoading, setIsLoading] = useState(false);





  const [img, setImg] = useState("");



  useEffect(() => {



    // Correctly invoke `generateToken` and set the token
    const fetchToken = async () => {
      const currentToken = await generateToken();
      console.log('currentToken token available......' + currentToken);
      if (currentToken) {
        setToken(currentToken);
      } else {
        console.log('No registration token available.');
      }
    };

    fetchToken();

  }, []);

  const loginNow = (phone, pass) => {
    setIsLoading(true); // Start loading
    const data = new FormData();
    data.append("email", phone);
    data.append("pass", pass);
    data.append("status", "login");
    data.append("did", "web");
    data.append("dtype", "web");
    data.append("token", token); // Use token if required

    axios({
      method: "post",
      url: "/pro/auth/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setIsLoading(false); // Stop loading
        if (response.data[0].msg === "pass") {
          alert("Thank you! Otp Verified. Login Successful! Redirecting...");
          localStorage.setItem("USER", JSON.stringify(response.data));
          setUid(response.data[0].user); // Update UID
          SendWelcome(response.data[0].user); // Send welcome notification
          navigate("/"); // Redirect to the home page
        } else {
          alert("Login failed! Please try again.");
        }
      })
      .catch((error) => {
        setIsLoading(false); // Stop loading
        console.error("Login Error:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    // alert(otp)
    data.append("otp", otp);
    data.append("status", "2");
    setIsLoading(true); // Start loading
    axios({
      method: "post",
      url: "/pro/auth/verify_user.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setIsLoading(false); // Stop loading
        console.log("===>  ", s.data)
        if (s.data[0].code == "pass") {
          //alert("Thank you!");
          // Retrieve signup details from localStorage
          const signupDetails = JSON.parse(localStorage.getItem("signupDetails"));

          if (signupDetails) {
            const { phone, pass } = signupDetails;

            // Perform login after OTP verification
            loginNow(phone, pass);
          } else {
            alert("No signup details found. Please log in manually.");
            navigate("/login");
          }
        } else if (s.data[0].code == "fail") {
          alert("OTP Worng !");
          seterror("name")


        }
        else {
          alert("OTP Expired");

        }
      })
      .catch((s) => {
        setIsLoading(false); // Stop loading
      });
  }

  const [otp, setOtp] = useState("");

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    let newOtp = otp;
    newOtp = newOtp.substring(0, index) + newValue + newOtp.substring(index + 1);
    if (index != 5) {
      document.getElementById(`form${index + 1}`).focus();
    }
    setOtp(newOtp);
  };



  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 col-md-6 col-lg-4 mt-5">
          <div onSubmit={handleSubmit} className="mt-5">
            <div className="form-group text-center py-5 form-container">
              {/* <h3>Enter OTP</h3> */}
              {/* <div className="w-full">
              <button
                className="float-right"
                onClick={() => {
                  if (!otp) {
                    onClose();
                  } else {
                    onClose();
                    navigate(`/register`);
                  }
                }}
              >
                <ImCross />
              </button>
            </div> */}
              <h1 className="fs-4 mb-5">
                OTP Verification
              </h1>
              <div className="d-flex justify-content-between px-2">
                  <input
                  className="otp-items form-control text-center border border-dark" maxLength="1" style={{ width: '155px' }}
                    type="number"
                    value={otp[0] || ''}
                    onChange={(e) => handleInputChange(e, 0)}
                  />
                  <input
                  className="otp-items form-control text-center border border-dark" maxLength="1" style={{ width: '155px' }}
                    type="number"
                    value={otp[1] || ""}
                    onChange={(e) => handleInputChange(e, 1)}
                    id="form1"
                  />
                  <input
                  className="otp-items form-control text-center border border-dark" maxLength="1" style={{ width: '155px' }}
                    type="number"
                    value={otp[2] || ""}
                    onChange={(e) => handleInputChange(e, 2)}
                    id="form2"
                  />
                  <input
                  className="otp-items form-control text-center border border-dark" maxLength="1" style={{ width: '155px' }}
                    type="number"
                    value={otp[3] || ""}
                    onChange={(e) => handleInputChange(e, 3)}
                    id="form3"
                  />
                  <input
                  className="otp-items form-control text-center border border-dark" maxLength="1" style={{ width: '155px' }}
                    type="number"
                    value={otp[4] || ""}
                    onChange={(e) => handleInputChange(e, 4)}
                    id="form4"
                  />
                  <input
                  className="otp-items form-control text-center border border-dark" maxLength="1" style={{ width: '155px' }}
                    type="number"
                    value={otp[5] || ""}
                    onChange={(e) => handleInputChange(e, 5)}
                    id="form5"
                  />
              </div>
              <button
              className="otp-btn"
              onClick={(e) => handleSubmit(e)}
                type="submit"
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default Login;
